import React from 'react'
import ReactDOM from 'react-dom/client'
import Routing from './Routing'
import './index.css';
import { QueryClientProvider } from "react-query"
import { queryClient } from './portal/utils/helper';
import { CssBaseline } from '@mui/material';

ReactDOM.createRoot(document.getElementById('root')).render(
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <Routing />
    </QueryClientProvider>
)
