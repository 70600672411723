export const ROUTES = {
  LOGIN: "/portal/login",
  DASHBOARD: "/portal/dashboard",
  SETTINGS: "/portal/settings",
  RESULTS: "/portal/result",
  ATTENDENCE: "/portal/attendence",
  FEE_DETAILS: "/portal/fee",
  PROFILE: "/portal/profile",
  ATTENDENCE_TODAY: "/portal/attendence/today",
  ATTENDENCE_RECORDS: "/portal/attendence/records",
  ATTENDENCE_UPDATE: "/portal/attendence/update",
  RESULTS_VIEW: "/portal/result/view",
  RESULTS_UPLOAD: "/portal/result/upload",
  SETTINGS_EDIT_PROFILE: "/portal/settings/profile",
  SETTINGS_ADD_USER: "/portal/settings/add",
  DASHBOARD_USER_LIST: "/portal/dashboard/users",
  DASHBOARD_STUDENT_LIST: "/portal/dashboard/students",
  DASHBOARD_ADD_STUDENT: "/portal/dashboard/add-student",
  DASHBOARD_PRINT_LIST: "/portal/dashboard/print-list",
  DASHBOARD_DETAIN_STUDENT: "/portal/dashboard/detain-students",
  DASHBOARD_ALUMNI_STUDENT: "/portal/dashboard/alumni-students",
  NOT_AUTHORISED: "/portal/not-authorized",
  DASHBOARD_STUDENT_DETAILS: (id) => `/portal/dashboard/students/${id}`,
  FEE_DETAILS_TOTAL_DATA: "/portal/fee/total",
  FEE_DETAILS_RANGED_DATA: "/portal/fee/ranged",
  FEE_DETAILS_EXPENSE_TRACK: "/portal/fee/expense",
};

export const API_CODES = {
  UNAUTHORIZED: 401,
};

export const COLOR_THEME = {
  header: "#6941C6",
  gray: "#667085",
  primary: "#7A5AF8",
  white: "#FFFFFF",
  dark: "#101828",
  hover: "#967AD7",
  light: "#F9FAFB",
  hoverLighter: "#DAD2FA",
};

export const USER_ROLES = {
  TEACHER: "TEACHER",
  ADMIN: "ADMIN",
  ADMISSION_CELL: "ADMISSION_CELL",
};

export const CLASS_LIST = [
  { label: "Nursery", value: -2 },
  { label: "L.K.G", value: -1 },
  { label: "U.K.G", value: 0 },
  ...new Array(12).fill().map((_, index) => ({
    label: `Class ${index + 1}`,
    value: index + 1,
  })),
];

export const GENDER_TYPE = {
  MALE: "MALE",
  FEMALE: "FEMALE",
};

export const PAGINATION = {
  LIMIT: 20,
};

export const SESSION_MONTHS = [
  {
    id: 1,
    name: "April",
    month: 4,
  },
  {
    id: 2,
    name: "May",
    month: 5,
  },
  {
    id: 3,
    name: "June",
    month: 6,
  },
  {
    id: 4,
    name: "July",
    month: 7,
  },
  {
    id: 5,
    name: "August",
    month: 8,
  },
  {
    id: 6,
    name: "September",
    month: 9,
  },
  {
    id: 7,
    name: "October",
    month: 10,
  },
  {
    id: 8,
    name: "November",
    month: 11,
  },
  {
    id: 9,
    name: "December",
    month: 12,
  },
  {
    id: 10,
    name: "January",
    month: 1,
  },
  {
    id: 11,
    name: "February",
    month: 2,
  },
  {
    id: 12,
    name: "March",
    month: 3,
  },
];
