import React from 'react'
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home"
import About_School from "./pages/About_School"

function PublicRoutes() {
  return (
    <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"about-school"} element={<About_School />} />
    </Routes>
  )
}

export default PublicRoutes