import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../custom-hooks/client";
import { getMobileBool } from "../../custom-hooks/mediaHooks";
import { COLOR_THEME, ROUTES, USER_ROLES } from "../../utils/constants";

const useStyles = makeStyles(() => ({
  sideMenu: {
    padding: "0.5rem 1rem",
    "&:hover": {
      backgroundColor: COLOR_THEME.hoverLighter,
    },
  },
}));

const FeeDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = getMobileBool();
  const loggedInUser = getCurrentUser();
  const isAdmin = loggedInUser?.role === USER_ROLES.ADMIN;

  useEffect(() => {
    if (!isAdmin) {
      navigate(ROUTES.NOT_AUTHORISED);
    }
  });

  const menuItems = [
    {
      label: "Ranged Data",
      path: ROUTES.FEE_DETAILS_RANGED_DATA,
    },
    {
      label: "Total Data",
      path: ROUTES.FEE_DETAILS_TOTAL_DATA,
    },
    {
      label: "Expense Track",
      path: ROUTES.FEE_DETAILS_EXPENSE_TRACK,
    },
  ];

  return (
    <Grid style={{ display: "flex" }}>
      <Box>
        {!isMobile && (
          <Box
            style={{
              minWidth: "15rem",
              display: "flex",
              flexDirection: "column",
              border: `1px solid ${COLOR_THEME.hoverLighter}`,
              marginTop: "2rem",
            }}
          >
            {menuItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className={classes.sideMenu}
                style={{
                  ...(location.pathname === item.path && {
                    backgroundColor: COLOR_THEME.primary,
                    color: "#FFF",
                  }),
                }}
              >
                {item.label}
              </Link>
            ))}
          </Box>
        )}
      </Box>
      <Box style={{ flex: 1, marginLeft: "1rem" }}>
        <Outlet />
      </Box>
    </Grid>
  );
};

export default FeeDetails;
