import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { getCurrentUser } from "../custom-hooks/client";
import { USER_ROLES } from "../utils/constants";
import { getSelectedClass } from "../utils/helper";

const useStyles = makeStyles(() => ({
  table: {
    "& td": {
      fontSize: "0.75rem",
      padding: "2px",
    },
  },
}));

const GenericFeeReceipt = ({ data, feeTab }) => {
  const last_submitted_fees = feeTab !== "previous" ? data?.fees?.fee_details?.at(-1) : data?.fees?.prev_fee_details?.at(-1);
  const prevPaidFee = feeTab !== "previous" ?
    (data?.fees?.total_fees -
    data?.fees?.balance_fees -
    parseInt(last_submitted_fees?.amount)) : (data?.fees?.prev_total_fees -
      data?.fees?.prev_balance_fees -
      parseInt(last_submitted_fees?.amount));

  const classes = useStyles();
  return (
    <Box sx={{ p: 2 }} style={{ flex: 1 }}>
      <Box display="flex">
        <div>
          <img src="/assets/b-w-logo.png" height="50" />
        </div>
        <div style={{ textAlign: "center" }}>
          <Typography style={{ fontSize: "1rem", fontWeight: 700 }}>
            S.B.S. SENIOR SECONDARY SCHOOL
          </Typography>
          <Typography style={{ fontSize: "0.75rem" }}>
            Kheriya, Neemgaon (Mathura)
          </Typography>
          <Typography style={{ fontSize: "0.75rem" }}>
            Mob No: 9719937863, 9719937862, 9719970179
          </Typography>
        </div>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography style={{ textAlign: "right" }}>
          <b>Date:</b> {format(new Date(), "dd-MM-yyyy")}
        </Typography>
        <Typography style={{ fontSize: "0.75rem" }}>
          <b style={{ marginRight: "1rem" }}>Name:</b>{" "}
          {data?.name?.toUpperCase()}
        </Typography>
        <Typography style={{ fontSize: "0.75rem" }}>
          <b style={{ marginRight: "1rem" }}>Father's Name:</b>{" "}
          {data?.father_name?.toUpperCase()}
        </Typography>
        <Typography style={{ fontSize: "0.75rem" }}>
          <b style={{ marginRight: "1rem" }}>Class:</b>{" "}
          {getSelectedClass(data?.class)}{" "}
          {data?.section && `- ${data?.section?.toUpperCase()}`}
        </Typography>
        <Typography style={{ fontSize: "0.75rem" }}>
          <b style={{ marginRight: "1rem" }}>Address:</b>{" "}
          {data?.address?.toUpperCase()}
        </Typography>
        <Typography style={{ fontSize: "0.75rem" }}>
          <b style={{ marginRight: "1rem" }}>Mobile:</b> {data?.mobile_number}
        </Typography>
      </Box>
      <table
        border="1"
        cellSpacing="0"
        style={{ width: "100%" }}
        className={classes.table}
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Particulars</th>
            <th>₹</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ textAlign: "right" }}>1.</td>
            <td>Previous submitted Total</td>
            <td style={{ textAlign: "right" }}>
              {prevPaidFee?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td rowspan="3">&nbsp;</td>
          </tr>
          <tr>
            <td style={{ textAlign: "right" }}>2.</td>
            <td>Dated {last_submitted_fees?.date} submitted fees</td>
            <td style={{ textAlign: "right" }}>
              {last_submitted_fees?.amount?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "right" }}>3.</td>
            <td>Total Paid</td>
            <td style={{ textAlign: "right" }}>
              {(
                feeTab !== "previous" ? (data?.fees?.total_fees - data?.fees?.balance_fees) : (data?.fees?.prev_total_fees - data?.fees?.prev_balance_fees)
              )?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          <tr>
            <td colspan="2" style={{ textAlign: "right", fontWeight: 700 }}>
              TOTAL
            </td>
            <td colspan="2" style={{ textAlign: "right" }}>
              {(feeTab !== "previous" ? data?.fees?.total_fees : data.fees?.prev_total_fees)?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          <tr>
            <td colspan="2" style={{ textAlign: "right", fontWeight: 700 }}>
              BALANCE
            </td>
            <td colspan="2" style={{ textAlign: "right" }}>
              {(
                (feeTab !== "previous" ? data?.fees?.balance_fees : data?.fees?.prev_balance_fees) + parseInt(last_submitted_fees?.amount)
              )?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          <tr>
            <td colspan="2" style={{ textAlign: "right", fontWeight: 700 }}>
              PAID
            </td>
            <td colspan="2" style={{ textAlign: "right" }}>
              {last_submitted_fees?.amount?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          <tr>
            <td colspan="2" style={{ textAlign: "right", fontWeight: 700 }}>
              NEW BALANCE
            </td>
            <td colspan="2" style={{ textAlign: "right" }}>
              {(feeTab !== "previous" ? data?.fees?.balance_fees : data?.fees?.prev_balance_fees)?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
        </tbody>
      </table>
      <Box display="flex" sx={{ mt: 8, justifyContent: "space-between" }}>
        <Typography>Signature (Receiver): -</Typography>
        <Typography>Signature (Fee Officer): -</Typography>
      </Box>
    </Box>
  );
};

const GenericFeeReceiptPrint = ({ data, feeTab }) => {
  const loggedInUser = getCurrentUser();
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  return (
    <>
      {loggedInUser?.role === USER_ROLES.ADMIN && (
        <Button onClick={handlePrint} variant="contained" sx={{ mt: 2 }}>
          Print Receipt
        </Button>
      )}
      <Box
        className="print-fee"
        display="flex"
        ref={printRef}
        style={{ display: "none" }}
      >
        <GenericFeeReceipt data={data} feeTab={feeTab}/>
        <GenericFeeReceipt data={data} feeTab={feeTab}/>
      </Box>
    </>
  );
};

export default GenericFeeReceiptPrint;
