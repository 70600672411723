import React from "react";
import GenericHeader from "../../components/GenericHeader";

function EditProfile() {
  return (
    <>
      <GenericHeader title="Edit Profile" />
      <div>EditProfile</div>
    </>
  );
}

export default EditProfile;
