import { compareAsc } from "date-fns";
import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
    },
  },
});

export const getSelectedClass = (selected) => {
  if (selected === -2) {
    return "Nursery";
  }
  if (selected === -1 && "L.K.G") {
    return "L.K.G";
  }
  if (selected === 0 && "U.K.G") {
    return "U.K.G";
  }
  if (![-2, -1, 0].includes(selected)) {
    return selected;
  }
};

export const toDate = (dateStr) => {
  const parts = dateStr.split("-")
  return new Date(parts[2], parts[1] - 1, parts[0])
}

export const areDateEqual = (a, b) => {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  )
}

export const isDateGreater = (a, b) => {
  return (
    compareAsc(a, b) === 1
  )
}

export const getStudentCellClassName = (row) => {
  const status = row?.status;
  const isPrevBalance = !!row?.fees?.prev_balance_fees;

  if (status === "NEW") return "newStudent";
  if ((status === "PROMOTED" || status === "DETAINED") && isPrevBalance)
    return "greyStudent";
  if ((status === "PROMOTED" || status === "DETAINED") && !isPrevBalance)
    return "yellowStudent";
  if (isPrevBalance) return "prevBalance";
  if (!isPrevBalance) return "noPrevBalance";
};