/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { getStudentList } from "../../custom-hooks/client";
import { CLASS_LIST } from "../../utils/constants";
import { getParsedFeeDetails } from "../../utils/feeDetailsParser";
import { getSelectedClass } from "../../utils/helper";
import Loader from "../Loader";
import FeeDetailsTableComponent from "./FeeDetailsTableComponent";

const FeeDetailPageComponent = () => {
  const { isLoading, data } = getStudentList(
    CLASS_LIST.map((item) => item?.value)
  );

  const [total, setTotal] = useState({
    total_fees: 0,
    balance_fees: 0,
    submitted_fees: 0,
  });

  const parsedFeeDetails = getParsedFeeDetails(data);

  const getTotalFee = (arr) => {
    let sum = 0;
    arr.forEach((item) => {
      if(!["DETAINED"].includes(item?.status)){
        sum += parseInt(item?.fees?.total_fees);
      }
    });
    return sum;
  };

  const getBalanceFee = (arr) => {
    let sum = 0;
    arr.forEach((item) => {
      if(!["DETAINED"].includes(item?.status)) {
        sum += parseInt(item?.fees?.balance_fees);
      }
    });
    return sum;
  };

  const getFeeSubmissionDetails = (arr) => {
    let res = {};
    res["total_fees"] = getTotalFee(arr);
    res["balance_fees"] = getBalanceFee(arr);
    res["submitted_fees"] = res?.total_fees - res?.balance_fees;
    setTotal((p) => ({
      total_fees: p?.total_fees + res?.total_fees,
      balance_fees: p?.balance_fees + res?.balance_fees,
      submitted_fees: p?.submitted_fees + res?.submitted_fees,
    }));
    return res;
  };

  const filteredRow = React.useMemo(() => {
    const rows = Object.entries(parsedFeeDetails)
      .sort(([a, _1], [b, _2]) => parseInt(a) - parseInt(b))
      .map(([class_value, arr]) => {
        return {
          id: +class_value + 10, // 10 is random seed value as some classes have negetive value
          class_value: ![-2, -1, 0].includes(parseInt(class_value))
            ? `Class ${getSelectedClass(class_value)}`
            : getSelectedClass(+class_value),
          ...getFeeSubmissionDetails(arr),
        };
      });
    return rows;
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box style={{ padding: "1.5rem 0 0 1.5rem" }}>
      <FeeDetailsTableComponent rows={filteredRow} />
      <Table style={{ paddingInline: "1rem 2rem" }}>
        <TableHead>
          <TableRow>
            <TableCell>Total Fees</TableCell>
            <TableCell>Submitted Fees</TableCell>
            <TableCell>Balance Fees</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              {total?.total_fees.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
              })}
            </TableCell>
            <TableCell>
              {total?.submitted_fees.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
              })}
            </TableCell>
            <TableCell>
              {total?.balance_fees.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
              })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default FeeDetailPageComponent;
