import React from "react";
import AuthValidator from "../components/AuthValidator";
import GenericHeader from "../components/GenericHeader";
import Layout from "../components/Layout/Layout";

const Profile = () => {
  return (
    <AuthValidator>
      <GenericHeader title="Profile" />
      <Layout>
        <div>Profile</div>
      </Layout>
    </AuthValidator>
  );
};

export default Profile;
