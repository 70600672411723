import { Box, Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { getCurrentUser } from "../../custom-hooks/client";
import { getMobileBool } from "../../custom-hooks/mediaHooks";
import { COLOR_THEME, ROUTES, USER_ROLES } from "../../utils/constants";
import { endPromotion } from "../../custom-hooks/administration";

const useStyles = makeStyles(() => ({
  sideMenu: {
    padding: "0.5rem 1rem",
    "&:hover": {
      backgroundColor: COLOR_THEME.hoverLighter,
    },
  },
}));

const SettingsComponent = () => {
  const classes = useStyles();
  const location = useLocation();
  const isMobile = getMobileBool();
  const loggedInUser = getCurrentUser();

  const endPromotionMutation = endPromotion();

  const handlePromotionEnded = () => {
    endPromotionMutation.mutate();
  };

  return (
    <Grid style={{ display: "flex" }}>
      {!isMobile && (
        <Box>
          <Box
            style={{
              minWidth: "15rem",
              display: "flex",
              flexDirection: "column",
              border: `1px solid ${COLOR_THEME.hoverLighter}`,
              marginTop: "2rem",
            }}
          >
            <Link
              to={ROUTES.SETTINGS_EDIT_PROFILE}
              className={classes.sideMenu}
              style={{
                ...(location.pathname === ROUTES.SETTINGS_EDIT_PROFILE && {
                  backgroundColor: COLOR_THEME.primary,
                  color: "#FFF",
                }),
              }}
            >
              Edit Profile
            </Link>
            {loggedInUser?.role === USER_ROLES.ADMIN && (
              <Link
                to={ROUTES.SETTINGS_ADD_USER}
                className={classes.sideMenu}
                style={{
                  ...(location.pathname === ROUTES.SETTINGS_ADD_USER && {
                    backgroundColor: COLOR_THEME.primary,
                    color: "#FFF",
                  }),
                }}
              >
                Register User
              </Link>
            )}
          </Box>
        </Box>
      )}
      <Box style={{ flex: 1, marginLeft: "1rem" }}>
        {location.pathname === ROUTES.SETTINGS && (
          <Button
            variant="contained"
            onClick={handlePromotionEnded}
            disabled={endPromotionMutation?.isLoading}
          >
            Promotions Ended
          </Button>
        )}
        <Outlet />
      </Box>
    </Grid>
  );
};

export default SettingsComponent;
