/* eslint-disable jsx-a11y/anchor-is-valid */
import { Close, Email, Menu, Phone } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../main.css";

function Header() {
  const [openMenuState, setOpenMenuState] = useState(false);

  function openMenu() {
    setOpenMenuState(true);
    document.querySelector("html").style.overflow = "hidden";
  }

  function closeMenu() {
    setOpenMenuState(false);
    document.querySelector("html").style.overflow = "visible";
  }

  return (
    <div className="header">
      <div
        className="label"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <span style={{ display: "flex", alignItems: "center" }}>
          <Phone style={{ fill: "#FFF" }} />
          +91-9719937862, +91-9719970179, +91-9719937863
        </span>
        <span style={{ display: "flex", alignItems: "center" }}>
          <Email style={{ fill: "#FFF" }} />
          sbscollege7@gmail.com
        </span>
      </div>
      <div className="nav-header">
        <div>
          <img src="assets/logo.jpg" width="120" alt="sbs-inter-college" />
        </div>
        <button className="hamburger-open" id="open-menu" onClick={openMenu}>
          <Menu style={{ fill: "#666", fontSize: "2.5rem" }} />
        </button>
        <ul className={`nav-menu ${openMenuState && "open"}`}>
          <button
            className="hamburger-close"
            id="close-menu"
            onClick={closeMenu}
          >
            <Close style={{ fill: "#FFF", fontSize: "2.5rem" }} />
          </button>
          <li className="menu-main selected">
            <a href="/">Home</a>
          </li>
          <li className="menu-main">
            <a href="#" className="down">
              About
            </a>
            <ul className="sub-menu">
              <li className="menu">About School</li>
              <li className="menu">Salient Features</li>
              <li className="menu">Our Mission & Vision</li>
              <li className="menu">Principal's Message</li>
              <li className="menu">School Rules</li>
              <li className="menu">School Uniform</li>
            </ul>
          </li>
          <li className="menu-main">
            <a href="#" className="down">
              Academic
            </a>
            <ul className="sub-menu">
              <li className="menu">Exam Policy</li>
              <li className="menu">Subject Combination</li>
              <li className="menu">Acedemic Planner</li>
              <li className="menu">Faculty List</li>
            </ul>
          </li>
          <li className="menu-main">
            <a href="#" className="down">
              Admission
            </a>
            <ul className="sub-menu">
              <li className="menu">Fee Structure</li>
              <li className="menu">School Fee</li>
              <li className="menu">Admission Schedule</li>
              <li className="menu">Admission Guidelines</li>
              <li className="menu">Download Application Form</li>
            </ul>
          </li>
          <li className="menu-main">
            <a href="#" className="down">
              Facilities
            </a>
            <ul className="sub-menu">
              <li className="menu">Infrastructure</li>
              <li className="menu">Transport</li>
              <li className="menu">School Library</li>
              <li className="menu">Smart Class</li>
              <li className="menu">Play Ground</li>
              <li
                className="menu"
                onClick={() =>
                  (window.location = "https://sbs-bingo-game.onrender.com")
                }
              >
                Bingo
              </li>
              <li
                className="menu"
                onClick={() =>
                  (window.location = "https://main--sbs-scrible.netlify.app/")
                }
              >
                Skribble
              </li>
            </ul>
          </li>
          <li className="menu-main">
            <a href="#" className="down">
              Download
            </a>
            <ul className="sub-menu">
              <li className="menu">Syllabus</li>
              <li className="menu">Download Datesheet</li>
            </ul>
          </li>
          <li className="menu-main">
            <a href="#">Activities</a>
          </li>
          <li className="menu-main">
            <a href="#" className="down">
              Gallery
            </a>
            <ul className="sub-menu">
              <li className="menu">Video Gallery</li>
              <li className="menu">Photo Gallery</li>
            </ul>
          </li>
          <li className="menu-main">
            <a>Contact</a>
          </li>
          <li className="menu-main">
            <Link to="/portal">Portal</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
