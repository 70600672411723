import { Comment, Map, Phone } from '@mui/icons-material'
import React from 'react'
import "../../main.css"

function Footer() {
  return (
    <div className="footer">
      <div className="upper-column-footer">
        <div className="about-us" style={{marginRight: "1rem", marginBottom: "1rem"}}>
          <h3>About Us</h3>
          <p>SBS Inter College has been established by the prime grace &amp; inspiration of lord Sh. Krishna. We have the
            vision to provide a modern learning level of education at Kheriya (Raya).</p>
        </div>
        <div className="school-map" style={{width: "50%", marginLeft: "1rem"}}>
          <h3>School Map</h3>
        </div>
      </div>

      <div className="contact-us">
        <div className="email"> <Comment style={{fill: "#FFF"}}/>
          <div>
            <p>Drop Us a Line</p>
            <p><a href="mailto:sbscollege7@gmail.com">sbscollege7@gmail.com</a></p>
          </div>
        </div>
        <div className="call-us"> <Phone style={{fill: "#FFF"}}/>
          <p className="call">Call Us Now</p>
          <p><a href="tel:+91-9719970179">+91-9719970179</a></p>
        </div>
        <div className="google-direction"> <Map style={{fill: "#FFF"}}/>
          <p>Get Direction</p>
        </div>
        <div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.656019023895!2d77.82406571498692!3d27.666112282809017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3973671e2881b237%3A0xc3e56314c1764d5!2sS.B.S%20inter%20collage%20kheriya%20neemgaon%20mant%20mathura!5e0!3m2!1sen!2sin!4v1657622962742!5m2!1sen!2sin" width="100%" height="300" style={{border: "0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Footer
