import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(() => ({
  relvCircularProgressBox: {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: "4rem",
  },

  relvCircularProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-20px 0 0 -20px",
  },
}));

const Loader = ({ className, ...other }) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.relvCircularProgressBox}`}>
      <CircularProgress
        size={40}
        className={`${classes.relvCircularProgress} ${className}`}
        {...other}
      />
    </Box>
  );
};

export default Loader;