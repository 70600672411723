import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { CircularProgress } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { loginUser } from "../services/clientServices";
import { ROUTES } from "../utils/constants";
import { queryClient } from "../utils/helper";

const LoginCard = () => {
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
      })}
      onSubmit={async (values) => {
        const data = {
          email: values.email,
          password: values.password,
        };
        try {
          const response = await loginUser(data);
          queryClient.setQueryData("currentUser", response?.user);
          localStorage.setItem("user", JSON.stringify(response?.user));
          localStorage.setItem("auth_token", JSON.stringify(response?.token));
          navigate(ROUTES.DASHBOARD);
        } catch (e) {
          setError(e.data);
        }
      }}
    >
      {(formProps) => (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              paddingTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {!!error.length && (
              <Typography
                sx={{ color: "red", background: "rgba(255, 0, 0, 0.1)", p: 1 }}
              >
                {error}
              </Typography>
            )}
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                style={{ minWidth: "23rem" }}
                id="email"
                label="Email Address"
                name="email"
                value={formProps.values.email}
                onChange={formProps.handleChange}
                onKeyDown={(e) =>
                  e.key === "Enter" ? formProps.handleSubmit() : null
                }
                autoComplete="email"
                autoFocus
              />
              {formProps.touched.email && formProps.errors.email && (
                <Typography sx={{ color: "red" }}>
                  {formProps.errors.email}
                </Typography>
              )}
              <TextField
                margin="normal"
                required
                style={{ minWidth: "23rem" }}
                name="password"
                label="Password"
                type="password"
                id="password"
                onKeyDown={(e) =>
                  e.key === "Enter" ? formProps.handleSubmit() : null
                }
                value={formProps.values.password}
                onChange={formProps.handleChange}
                autoComplete="current-password"
              />
              {formProps.touched.password && formProps.errors.password && (
                <Typography sx={{ color: "red" }}>
                  {formProps.errors.email}
                </Typography>
              )}
              <Button
                type="submit"
                style={{ minWidth: "23rem" }}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={formProps.handleSubmit}
                disabled={formProps.isSubmitting}
              >
                {formProps.isSubmitting ? <CircularProgress /> : "Sign In"}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      )}
    </Formik>
  );
};

export default LoginCard;
