/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header";

function home() {
  const [slideIndex, setSlideIndex] = useState(1);

  const plusSlides = (n) => {
    setSlideIndex((p) => p + n);
  };

  useEffect(() => {
    let i;
    let slides = document.getElementsByClassName("mySlides");
    if (slideIndex > slides.length) {
      setSlideIndex(1);
      return;
    }
    if (slideIndex < 1) {
      setSlideIndex(slides.length);
      return;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[slideIndex - 1].style.display = "block";
  }, [slideIndex]);

  return (
    <>
      <Helmet>
        <title>Home | SBS Inter College</title>
      </Helmet>
      <Header />
      <div className="slideshow-container">
        <div className="mySlides fade">
          <img src="assets/new-bus.jpeg" style={{ width: "100%" }} />
        </div>

        <div className="mySlides fade">
          <img src="assets/school.jpeg" style={{ width: "100%" }} />
        </div>

        <div className="mySlides fade">
          <img src="assets/slider (1).jpeg" style={{ width: "100%" }} />
        </div>

        <div className="mySlides fade">
          <img src="assets/slider (2).jpeg" style={{ width: "100%" }} />
        </div>

        <div className="mySlides fade">
          <img src="assets/slider (3).jpeg" style={{ width: "100%" }} />
        </div>

        <div className="mySlides fade">
          <img src="assets/slider (4).jpeg" style={{ width: "100%" }} />
        </div>

        <div className="mySlides fade">
          <img src="assets/slider (5).jpeg" style={{ width: "100%" }} />
        </div>

        <div className="mySlides fade">
          <img src="assets/slider (6).jpeg" style={{ width: "100%" }} />
        </div>

        <div className="mySlides fade">
          <img src="assets/slider (8).jpeg" style={{ width: "100%" }} />
        </div>

        <a className="prev" onClick={() => plusSlides(-1)}>
          ❮
        </a>
        <a className="next" onClick={() => plusSlides(1)}>
          ❯
        </a>
      </div>
      <br />
      <div className="school-container">
        <div className="school-img-section">
          <img src="assets/school.jpeg" width="350" alt="photo 1" />
        </div>
        <div className="about-school-section">
          <h3 style={{ marginBottom: "1rem" }}>Welcome to</h3>
          <h2 style={{ marginBottom: "1rem" }}>SBS Inter College</h2>
          <p>
            SBS Inter College has been established by the prime grace &amp;
            inspiration of lord Sh. Krishna. We have the vision to provide a
            modern learning level of education at Kheriya. We have the vision to
            provide a modern learning environment on the campus without damaging
            the roots of our rich culture and civilization.
          </p>
          <p>
            SBS Inter College is a modern co-educational Hindi Medium School.
            established in 2010 by Shri Pratap Singh Ji, Shri Rishi Pal Singh Ji
            and Shri Veerpal Singh Ji. It provides a sounds academic, social,
            and moral education to all students, regardless of Cast, Creed &amp;
            Religion.
          </p>
          <div
            className="about-school-icons-container"
            style={{ marginTop: "1rem" }}
          >
            <div className="icons-1">
              <span>
                <i
                  className="fa-solid fa-computer"
                  style={{ marginBottom: "1rem" }}
                ></i>
                Smart className
              </span>
              <span>
                <i className="fa-solid fa-person-chalkboard"></i>Qualified
                Teachers
              </span>
            </div>
            <div className="icons-2">
              <span>
                <i
                  className="fa-solid fa-bus"
                  style={{ marginBottom: "1rem" }}
                ></i>
                Transportation
              </span>
              <span>
                <i className="fa-solid fa-futbol"></i>Play Ground
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="director-message">
        <div className="director-image">
          <img src="assets/photo.jpg" width="250" alt="photo 1" />
        </div>
        <div className="director-message-text">
          <h3 style={{ marginBottom: "1rem" }}>From the Desk</h3>
          <h2 style={{ marginBottom: "1rem" }}>Director's Message</h2>
          <p style={{ marginBottom: "1rem" }}>
            Dear Parents, Guardians, Students. I extend you a warm welcome to
            SBS Inter College. In Today's world, new challenges with newer
            fields emerging or appearing every day in all aspects of human
            existence. One can now choose a career from the myriad of available
            options, be it Science. Technology, Arts, Sports, Music, Commerce,
            etc. However, to make the most of the opportunities one needs to
            have access to world-className education and grooming to be prepared
            to confidently face the changing scenario with courage and be
            successful in their endeavours.
          </p>
          <h4 style={{ marginBottom: "1rem" }}>Shri Vipin Chaudhary</h4>
          <a href="">
            Read More<i className="fa-solid fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div className="hover-element">
        <div className="hover-card">
          <h2 className="hover-title">Class 10th Toppers - 2022</h2>
          <div className="hover-image-section">
            <div className="hover-image" style={{ marginRight: "0.5rem" }}>
              <img src="assets/ankit-kumar.jpeg" alt="Ankit Kumar" />
              <h2 style={{ fontWeight: 700, color: "var(--orange)" }}>
                Ankit Kumar
              </h2>
              <h3>90.83%</h3>
              <h3>
                District Rank - <span style={{ fontSize: "1.5rem" }}>7</span>
              </h3>
            </div>
            <div className="hover-image" style={{ marginRight: "0.5rem" }}>
              <img src="assets/ankit-kaushik.jpeg" alt="Ankit Kaushik" />
              <h2 style={{ fontWeight: 700, color: "var(--orange)" }}>
                Ankit Kaushik
              </h2>
              <h3>90.67%</h3>
              <h3>
                District Rank - <span style={{ fontSize: "1.5rem" }}>8</span>
              </h3>
            </div>
            <div className="hover-image" style={{ marginRight: "0.5rem" }}>
              <img src="assets/Khusi-Sharma.jpeg" alt="Khushi Sharma" />
              <h2 style={{ fontWeight: 700, color: "var(--orange)" }}>
                Khusi Sharma
              </h2>
              <h3>89.34%</h3>
            </div>
            <div className="hover-image">
              <img src="assets/Mohit-Kumar.jpeg" alt="Mohit Kumar" />
              <h2 style={{ fontWeight: 700, color: "var(--orange)" }}>
                Mohit Sharma
              </h2>
              <h3>88.67%</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="gallery">
        <h2>
          Gallery
          <a href="">
            More<i className="fa-solid fa-arrow-right"></i>
          </a>
        </h2>
        <div className="image-section">
          <div className="gallery-image">
            <img src="assets/slider (3).jpeg" width="300" />
            <h4>Morning Assembly 2022</h4>
          </div>
          <div className="gallery-image">
            <img src="assets/slider (4).jpeg" width="300" />
            <h4>2022</h4>
          </div>
          <div className="gallery-image">
            <img src="assets/slider (5).jpeg" width="300" />
            <h4>Front Gate 2022</h4>
          </div>
          <div className="gallery-image">
            <img src="assets/slider (6).jpeg" width="300" />
            <h4>Playground 2022</h4>
          </div>
          <div className="gallery-image">
            <img src="assets/slider (8).jpeg" width="300" />
            <h4>Vasant Panchmi Poojan 2022</h4>
          </div>
          <div className="gallery-image">
            <img src="assets/new-bus.jpeg" width="300" />
            <h4>New Bus, July 2022</h4>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default home;
