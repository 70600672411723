import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { getCurrentUser } from "../../custom-hooks/client";
import { getMobileBool } from "../../custom-hooks/mediaHooks";
import { COLOR_THEME, ROUTES, USER_ROLES } from "../../utils/constants";

const useStyles = makeStyles(() => ({
  sideMenu: {
    padding: "0.5rem 1rem",
    "&:hover": {
      backgroundColor: COLOR_THEME.hoverLighter,
    },
  },
}));

const DashboardComponent = () => {
  const classes = useStyles();
  const location = useLocation();
  const isMobile = getMobileBool();
  const loggedInUser = getCurrentUser();
  const isAdmin = loggedInUser?.role === USER_ROLES.ADMIN;

  const menuItems = [
    {
      label: "Students List",
      path: ROUTES.DASHBOARD_STUDENT_LIST,
    },
    {
      label: "Add Student",
      path: ROUTES.DASHBOARD_ADD_STUDENT,
    },
    {
      label: "Print Student Lists",
      path: ROUTES.DASHBOARD_PRINT_LIST,
    },
    ...(isAdmin
      ? [
          {
            label: "Users List",
            path: ROUTES.DASHBOARD_USER_LIST,
          },
          {
            label: "Detained Student",
            path: ROUTES.DASHBOARD_DETAIN_STUDENT,
          },
          {
            label: "Alumni Student",
            path: ROUTES.DASHBOARD_ALUMNI_STUDENT,
          },
        ]
      : []),
  ];

  return (
    <Grid style={{ display: "flex" }}>
      <Box>
        {!isMobile && (
          <Box
            style={{
              minWidth: "15rem",
              display: "flex",
              flexDirection: "column",
              border: `1px solid ${COLOR_THEME.hoverLighter}`,
              marginTop: "2rem",
            }}
          >
            {menuItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className={classes.sideMenu}
                style={{
                  ...(location.pathname === item.path && {
                    backgroundColor: COLOR_THEME.primary,
                    color: "#FFF",
                  }),
                }}
              >
                {item.label}
              </Link>
            ))}
          </Box>
        )}
      </Box>
      <Box style={{ flex: 1, marginLeft: "1rem" }}>
        <Outlet />
      </Box>
    </Grid>
  );
};

export default DashboardComponent;
