import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import React, { useState } from "react";
import { getStudentList } from "../../custom-hooks/client";
import { CLASS_LIST } from "../../utils/constants";
import { getParsedFeeDetails } from "../../utils/feeDetailsParser";
import { getSelectedClass, queryClient, toDate } from "../../utils/helper";
import GenericPrintList from "../GenericPrintList";
import Loader from "../Loader";
import FeeDetailsTableComponent from "./FeeDetailsTableComponent";
import FeeDetailStudentComponent from "./FeeDetailStudentComponent";

const FeeDetailDateRangeComponent = () => {
  const [startDate, setStartDate] = useState(
    queryClient.getQueryData("fee_start_date") ?? new Date()
  );
  const [endDate, setEndDate] = useState(
    queryClient.getQueryData("fee_end_date") ?? new Date()
  );
  const [total, setTotal] = useState(0);
  const [selectedClass, setSelectedClass] = useState();
  const [classList, setClassList] = useState({});
  const [showNil, setShowNil] = useState(
    queryClient.getQueryData("fee_show_nil") ?? false
  );
  const [showPrev, setShowPrev] = useState(
    queryClient.getQueryData("fee_show_prev") ?? false
  );
  const [showPrevLeft, setShowPrevLeft] = useState(
    queryClient.getQueryData("fee_show_prev_left") ?? false
  )

  const { isLoading, data } = getStudentList(
    CLASS_LIST.map((item) => item?.value)
  );

  const parsedFeeDetails = getParsedFeeDetails(data);

  const getFeeSubmissionDetails = (arr, class_value) => {
    let list = [];
    let sum = 0;
    arr.forEach((student) => {
      sum += (showPrev ? student?.fees?.prev_fee_details : student?.fees?.fee_details)?.filter((item) => {
          const current_date = toDate(item?.date);
          if (
            (!startDate && !endDate) ||
            (current_date.getTime() <= endDate?.setHours(0, 0, 0, 0) &&
              current_date.getTime() >= startDate?.setHours(0, 0, 0, 0))
          ) {
            list.push(student);
            return true;
          }
          return false;
        })
        .reduce((s, i) => s + parseInt(i?.amount ?? 0), 0);
    });
    setTotal((p) => p + sum);
    setClassList((p) => ({ ...p, [class_value]: [...new Set(list)] }));
    return sum;
  };

  const getPrevLeftFeesData = (arr) => {
    let sum = arr.reduce((s, i) => s + parseInt(i?.fees?.prev_balance_fees ?? 0), 0);
    setTotal((p) => p + sum);
    return sum;
  }

  const getNillStudentCount = (arr, class_value) => {
    const list = arr.filter(
      (item) => (showPrev ? (item?.fees?.prev_total_fees == item?.fees?.prev_balance_fees) : (item?.fees?.total_fees == item?.fees?.balance_fees)) && item?.status !== "DETAINED");
    setClassList((p) => ({ ...p, [class_value]: list }));
    setTotal(0);
    return list?.length ?? 0;
  };

  const filteredRow = React.useMemo(() => {
    const rows = Object.entries(parsedFeeDetails)
      .sort(([a, _1], [b, _2]) => parseInt(a) - parseInt(b))
      .map(([class_value, arr]) => {
        return {
          id: +class_value + 10, // 10 is random seed value as some classes have negetive value
          class_value: ![-2, -1, 0].includes(parseInt(class_value))
            ? `Class ${getSelectedClass(class_value)}`
            : getSelectedClass(+class_value),
          ...(!showNil && {
            submitted_fees: getFeeSubmissionDetails(arr, class_value),
          }),
          ...(showNil && {
            student_count: getNillStudentCount(arr, class_value),
          }),
          ...(showPrevLeft && {
            balance_fees: getPrevLeftFeesData(arr),
          })
        };
      });
    return rows;
  }, [data, startDate, endDate, showNil, showPrev, showPrevLeft]);

  if (isLoading) {
    return <Loader />;
  }

  if (
    (selectedClass || selectedClass === 0) &&
    classList[selectedClass]?.length
  ) {
    return (
      <>
        <Box display="flex" sx={{ pt: 1, pb: 1 }} alignItems="center">
          <Typography variant="h5">
            {getSelectedClass(selectedClass)} |{" "}
            {startDate && endDate
              ? `${format(startDate, "dd-MM-yyyy")} - ${format(
                  endDate,
                  "dd-MM-yyyy"
                )}`
              : "All Time"}
          </Typography>
          <Button
            variant="contained"
            sx={{ ml: 2 }}
            onClick={() => setSelectedClass(null)}
          >
            Back
          </Button>
        </Box>
        <FeeDetailStudentComponent data={classList[selectedClass]} isPrevFeeData={showPrev}/>
        <GenericPrintList
          list={classList[selectedClass]}
          title={`Class ${getSelectedClass(selectedClass)}`}
        />
      </>
    );
  }

  return (
    <Box style={{ padding: "1.5rem 0 0 1.5rem" }}>
      <Grid>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            style={{
              display: "flex",
              gap: "2rem",
              alignItems: "center",
              marginBottom: "1.5rem",
              flexDirection: "row",
            }}
          >
            <MobileDatePicker
              label="Start Date"
              name={"start_date"}
              value={startDate}
              inputFormat="dd-MM-yyyy"
              onChange={(newValue) => {
                setStartDate(newValue);
                setTotal(0);
                queryClient.setQueryData("fee_start_date", newValue);
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
            <MobileDatePicker
              label="End Date"
              name={"end_date"}
              value={endDate}
              inputFormat="dd-MM-yyyy"
              onChange={(newValue) => {
                setEndDate(newValue);
                setTotal(0);
                queryClient.setQueryData("fee_end_date", newValue);
              }}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
            <Box>
              {!showPrevLeft && (<>
                  <FormControlLabel
                    checked={showNil}
                    onChange={() =>
                      setShowNil((p) => {
                        queryClient.setQueryData("fee_show_nil", !p);
                        setShowPrevLeft(false);
                        return !p;
                      })
                    }
                    control={<Checkbox />}
                    label="Nil"
                    />
              </>)}
                  <FormControlLabel
                    checked={showPrev}
                    onChange={() =>
                      setShowPrev((p) => {
                        setTotal(0)
                        queryClient.setQueryData("fee_show_prev", !p);
                        setShowPrevLeft(false);
                        return !p;
                      })
                    }
                    control={<Checkbox />}
                    label="Prev"
                  />
              {!showNil && showPrev && (<FormControlLabel
                checked={showPrevLeft}
                onChange={() =>
                  setShowPrevLeft((p) => {
                    setTotal(0)
                    queryClient.setQueryData("fee_show_prev_left", !p);
                    setShowNil(false);
                    return !p;
                  })
                }
                control={<Checkbox />}
                label="Prev Left"
              />)}
            </Box>
          </Box>
        </LocalizationProvider>
      </Grid>
      <FeeDetailsTableComponent
        rows={filteredRow}
        ranged
        setSelectedClass={setSelectedClass}
        showNil={showNil}
        showPrevLeft={showPrevLeft}
      />
      {!showNil && (
        <Table style={{ paddingInline: "1rem 2rem" }}>
          <TableHead>
            <TableRow>
              <TableCell>{!showPrevLeft ? "Submitted Fees" : "Balance Fees"}</TableCell>
              <TableCell>
                {total?.toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      )}
    </Box>
  );
};

export default FeeDetailDateRangeComponent;
