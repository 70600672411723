import { deleteAPIService, getAPIService, postAPIService, putAPIService } from "../services/index"

export const registerUser = async (data) => {
  try {
    const res = await postAPIService("/auth/register", data);
    return res.data;
  } catch (e) {
    throw e;
  }
} 

export const registerStudent = async (data) => {
  try {
    const res = await postAPIService("/student", data);
    return res.data;
  } catch (e) {
    throw e;
  }
}

export const editStudent = async (data, id) => {
  try {
    const res = await putAPIService(`/student/${id}`, data, "multipart/form-data");
    return res.data;
  } catch (e) {
    throw e;
  }
}

export const removeStudent = async (id) => {
  try {
    const res = await deleteAPIService(`/student/${id}`);
    return res.data;
  } catch (e) {
    throw e;
  }
}

export const getTurnoverYears = async () => {
  try {
    const res = await getAPIService("/turnover/turnover-year");
    return res.data;
  } catch (e) {
    throw e;
  }
}
export const getTurnover = async () => {
  try {
    const res = await getAPIService("/turnover");
    return res.data;
  } catch (e) {
    throw e;
  }
}

export const postTurnOverDetails = async (data) => {
  try {
    const res = await postAPIService("/turnover", data);
    return res.data;
  } catch (e) {
    throw e;
  }
}

export const putTurnOverDetails = async (data) => {
  try {
    const res = await putAPIService("/turnover", data);
    return res.data;
  } catch (e) {
    throw e;
  }
}

export const postTurnOverSession = async (data) => {
  try {
    const res = await postAPIService("/turnover/turnover-year", data);
    return res.data;
  } catch (e) {
    throw e;
  }
}

export const postPromotionEnded = async () => {
  try {
    const res = await postAPIService("/promotion-ended");
    return res.data;
  } catch (e) {
    throw e;
  }
};