import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Main from "./pages/Main";
import Dashboard from "./pages/Dashboard/Dashboard";
import UserList from "./pages/Dashboard/UserList";
import StudentList from "./pages/Dashboard/StudentList";
import AddStudent from "./pages/Dashboard/AddStudent";
import Settings from "./pages/Settings/Settings";
import EditProfile from "./pages/Settings/EditProfile";
import RegisterUser from "./pages/Settings/RegisterUsers";
import Fee from "./pages/Fee";
import Attendence from "./pages/Attendence/Attendence";
import Results from "./pages/Results/Results";
import ViewResults from "./pages/Results/ViewResults";
import UploadMarks from "./pages/Results/UploadMarks";
import Profile from "./pages/Profile";
import TodaysAttendence from "./pages/Attendence/TodaysAttendence";
import AttendenceRecords from "./pages/Attendence/AttendenceRecords";
import UpdateAttendence from "./pages/Attendence/UpdateAttendence";
import NotAuth from "./pages/NotAuth";
import NotFound from "./pages/NotFound";
import FeeDetailPageComponent from "./components/FeeDetails/FeeDetailPageComponent";
import FeeDetailDateRangeComponent from "./components/FeeDetails/FeeDetailDateRangeComponent";
import FeeDetailPageExpenseTrack from "./components/FeeDetails/FeeDetailPageExpenseTrack";
import DetainStudentList from "./pages/Dashboard/DetainStudentList";
import AlumniStudentList from "./pages/Dashboard/AlumniStudentList";
import PrintLists from "./components/Dashboard/PrintLists";

function PortalRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="login" element={<Login />} />
      <Route path="dashboard" element={<Dashboard />}>
        <Route path="print-list" element={<PrintLists />} />
        <Route path="users" element={<UserList />} />
        <Route path="students" element={<StudentList />} />
        <Route path="detain-students" element={<DetainStudentList />} />
        <Route path="alumni-students" element={<AlumniStudentList />} />
        <Route path="students" element={<StudentList />} />
        <Route path="add-student" element={<AddStudent />} />
        <Route path="students/:studentId" element={<AddStudent />} />
      </Route>
      <Route path="settings" element={<Settings />}>
        <Route path="profile" element={<EditProfile />} />
        <Route path="add" element={<RegisterUser />} />
      </Route>
      <Route path="fee" element={<Fee />}>
        <Route path="ranged" element={<FeeDetailDateRangeComponent />} />
        <Route path="total" element={<FeeDetailPageComponent />} />
        <Route path="expense" element={<FeeDetailPageExpenseTrack />} />
      </Route>
      <Route path="attendence" element={<Attendence />}>
        <Route path="today" element={<TodaysAttendence />} />
        <Route path="records" element={<AttendenceRecords />} />
        <Route path="update" element={<UpdateAttendence />} />
      </Route>
      <Route path="result" element={<Results />}>
        <Route path="view" element={<ViewResults />} />
        <Route path="upload" element={<UploadMarks />} />
      </Route>
      <Route path="profile" element={<Profile />} />
      <Route path="not-authorized" element={<NotAuth />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PortalRoutes;
