import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { currentUser } from "../custom-hooks/client";
import { API_CODES, ROUTES } from "../utils/constants";
import { queryClient } from "../utils/helper";
import Loader from "./Loader";

const PUBLIC_ROUTES = [ROUTES.LOGIN];

const AuthValidator = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isPublicRoute = PUBLIC_ROUTES.includes(location?.pathname);

  const user = queryClient.getQueryData("currentUser");
  const {isLoading, error, isError} = currentUser();

  const [isAuthenticated, setIsAuthenticated] = useState(
    isPublicRoute || !!user?.email
  );

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem("user"));
    const isLogged = isAuthenticated || localUser;
    !isLogged && navigate(ROUTES.LOGIN);
    if(user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
    if(isError) {
      if(error?.status === API_CODES.UNAUTHORIZED)
        navigate(ROUTES.LOGIN);
      else
        navigate(ROUTES.DASHBOARD);
    }
    setIsAuthenticated(isPublicRoute || !!localUser?.email);
  }, [isAuthenticated, user, isLoading]);
  
  if (!isAuthenticated || !user) {
    return <Loader />;
  }

  return children;
};

export default React.memo(AuthValidator);
