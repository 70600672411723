import {
  Assessment,
  ChevronRight,
  CurrencyRupee,
  Dashboard,
  ExpandMore,
  Settings,
  TableRows,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  Modal,
  Slide,
  Typography,
  Container,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getMobileBool } from "../../custom-hooks/mediaHooks";
import Header from "../Header";
import { COLOR_THEME, ROUTES, USER_ROLES } from "../../utils/constants";
import { getCurrentUser } from "../../custom-hooks/client";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    backgroundColor: COLOR_THEME.header,
    padding: "0.75rem 1rem",
    justifyContent: "space-between",
    alignItems: "center",
  },
  model: {
    backgroundColor: COLOR_THEME.light,
    height: "100%",
    width: "80%",
    outline: "none",
    position: "relative",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
  },

  desktopMenuItem: {
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "space-between",
  },

  iconsHover: {
    "&:hover": {
      fill: `${COLOR_THEME.header} !important`,
    },
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const [openSidePanel, setSidePanelOpen] = useState(false);
  const [openAccordian, setAccordianOpen] = useState([]);
  const isMobile = getMobileBool();
  const location = useLocation();
  const loggedInUser = getCurrentUser();
  const isAdmin = loggedInUser?.role === USER_ROLES.ADMIN;
  const isAdmissionCell = loggedInUser?.role === USER_ROLES.ADMISSION_CELL;

  const SIDEPANEL_ITEMS = [
    {
      title: "Dashboard",
      icon: ({ active, styleProps }) => (
        <Dashboard
          style={{
            fill: active ? COLOR_THEME.primary : COLOR_THEME.gray,
            ...styleProps,
          }}
          className={classes.iconsHover}
        />
      ),
      path: ROUTES.DASHBOARD,
      menu_items: [
        {
          label: "Students List",
          path: ROUTES.DASHBOARD_STUDENT_LIST,
        },
        {
          label: "Add Student",
          path: ROUTES.DASHBOARD_ADD_STUDENT,
        },
        {
          label: "Print Student Lists",
          path: ROUTES.DASHBOARD_PRINT_LIST,
        },
        ...(isAdmin
          ? [
              {
                label: "Users List",
                path: ROUTES.DASHBOARD_USER_LIST,
              },
              {
                label: "Detained Student",
                path: ROUTES.DASHBOARD_DETAIN_STUDENT,
              },
              {
                label: "Alumni Student",
                path: ROUTES.DASHBOARD_ALUMNI_STUDENT,
              },
            ]
          : []),
      ],
    },
    ...(!isAdmissionCell
      ? [
          {
            title: "Attendence",
            icon: ({ active, styleProps }) => (
              <TableRows
                style={{
                  fill: active ? COLOR_THEME.primary : COLOR_THEME.gray,
                  ...styleProps,
                }}
                className={classes.iconsHover}
              />
            ),
            path: ROUTES.ATTENDENCE,
            menu_items: [
              {
                label: "Mark Today's Attendence",
                path: ROUTES.ATTENDENCE_TODAY,
              },
              {
                label: "Update Attendence",
                path: ROUTES.ATTENDENCE_UPDATE,
              },
              {
                label: "Records",
                path: ROUTES.ATTENDENCE_RECORDS,
              },
            ],
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            title: "Fee Details",
            icon: ({ active, styleProps }) => (
              <CurrencyRupee
                style={{
                  fill: active ? COLOR_THEME.primary : COLOR_THEME.gray,
                  ...styleProps,
                }}
                className={classes.iconsHover}
              />
            ),
            path: ROUTES.FEE_DETAILS,
            menu_items: [
              {
                label: "Ranged Data",
                path: ROUTES.FEE_DETAILS_RANGED_DATA,
              },
              {
                label: "Total Data",
                path: ROUTES.FEE_DETAILS_TOTAL_DATA,
              },
              {
                label: "Expense Track",
                path: ROUTES.FEE_DETAILS_EXPENSE_TRACK,
              },
            ],
          },
        ]
      : []),
    ...(!isAdmissionCell
      ? [
          {
            title: "Result",
            icon: ({ active, styleProps }) => (
              <Assessment
                style={{
                  fill: active ? COLOR_THEME.primary : COLOR_THEME.gray,
                  ...styleProps,
                }}
                className={classes.iconsHover}
              />
            ),
            path: ROUTES.RESULTS,
            menu_items: [
              {
                label: "View Results",
                path: ROUTES.RESULTS_VIEW,
              },
              {
                label: "Upload Marks",
                path: ROUTES.RESULTS_UPLOAD,
              },
            ],
          },
        ]
      : []),
    {
      title: "Settings",
      icon: ({ active, styleProps }) => (
        <Settings
          style={{
            fill: active ? COLOR_THEME.primary : COLOR_THEME.gray,
            ...styleProps,
          }}
          className={classes.iconsHover}
        />
      ),
      path: ROUTES.SETTINGS,
      menu_items: [
        {
          label: "Edit Profile",
          path: ROUTES.SETTINGS_EDIT_PROFILE,
        },
        ...(isAdmin
          ? [
              {
                label: "Register New User",
                path: ROUTES.SETTINGS_ADD_USER,
              },
            ]
          : []),
      ],
    },
  ];

  const toggleAccordian = (item) => {
    if (openAccordian.includes(item)) {
      return setAccordianOpen((p) => p.filter((item1) => item1 !== item));
    }
    return setAccordianOpen((p) => [...p, item]);
  };

  return (
    <Box style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Header
        setSidePanelOpen={setSidePanelOpen}
        openSidePanel={openSidePanel}
      />
      <Grid
        style={{
          display: "flex",
          flex: 1,
        }}
      >
        {!isMobile && (
          <Box
            style={{
              height: "100%",
              backgroundColor: COLOR_THEME.light,
              borderRight: `2px solid ${COLOR_THEME.hoverLighter}`,
              display: "flex",
              flexDirection: "column",
              padding: "0.75rem",
            }}
          >
            {SIDEPANEL_ITEMS.map((item, index) => (
              <Link to={item.path} key={index}>
                <Box
                  className={classes.desktopMenuItem}
                  style={{ ...(openSidePanel && { minWidth: "15rem" }) }}
                >
                  <Tooltip
                    title={!openSidePanel ? item.title : ""}
                    arrow
                    placement="bottom"
                  >
                    <span>
                      <item.icon
                        active={location?.pathname.includes(item.path)}
                        styleProps={{ fontSize: "2rem" }}
                      />
                    </span>
                  </Tooltip>
                  {openSidePanel && (
                    <>
                      <Typography
                        style={{
                          color: location?.pathname.includes(item.path)
                            ? COLOR_THEME.primary
                            : COLOR_THEME.dark,
                        }}
                      >
                        {item.title}
                      </Typography>
                      <ChevronRight
                        style={{
                          fill: location?.pathname.includes(item.path)
                            ? COLOR_THEME.primary
                            : COLOR_THEME.gray,
                        }}
                      />
                    </>
                  )}
                </Box>
              </Link>
            ))}
          </Box>
        )}
        <Container
          maxWidth="xl"
          style={{ backgroundColor: "#FFF", height: "100%", paddingLeft: 0 }}
        >
          {children}
        </Container>
      </Grid>
      <Modal
        open={openSidePanel && isMobile}
        onClose={() => setSidePanelOpen(false)}
        closeAfterTransition
      >
        <Slide direction="right" in={openSidePanel}>
          <Box className={classes.model}>
            <CloseIcon
              style={{
                fill: COLOR_THEME.primary,
                position: "relative",
                top: "1rem",
                left: "calc(100% - 2.25rem)",
                fontSize: "2rem",
                marginBottom: "1rem",
              }}
              onClick={() => setSidePanelOpen(false)}
            />
            {SIDEPANEL_ITEMS.map((item) => (
              <Accordion
                key={item.title}
                expanded={
                  !!item.menu_items.length && openAccordian.includes(item.path)
                }
                onClick={() => toggleAccordian(item.path)}
                style={{
                  boxShadow: "none",
                  backgroundColor: COLOR_THEME.light,
                }}
              >
                <AccordionSummary
                  className={classes.menuItem}
                  expandIcon={
                    !!item.menu_items.length ? (
                      <ExpandMore
                        style={{
                          fill: location?.pathname.includes(item.path)
                            ? COLOR_THEME.primary
                            : COLOR_THEME.gray,
                        }}
                      />
                    ) : (
                      <ChevronRight
                        style={{
                          fill: location?.pathname.includes(item.path)
                            ? COLOR_THEME.primary
                            : COLOR_THEME.gray,
                        }}
                      />
                    )
                  }
                >
                  <item.icon active={location?.pathname.includes(item.path)} />
                  <Typography
                    style={{
                      color: location?.pathname.includes(item.path)
                        ? COLOR_THEME.primary
                        : COLOR_THEME.gray,
                      fontSize: "1.20rem",
                      marginLeft: "1rem",
                    }}
                  >
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: COLOR_THEME.gray }}>
                  {item.menu_items.map((subMenu, index) => (
                    <Link
                      key={index}
                      to={subMenu.path}
                      onClick={() => setSidePanelOpen(false)}
                    >
                      <Typography
                        style={{
                          color: location.pathname.includes(subMenu.path)
                            ? COLOR_THEME.primary
                            : "#FFF",
                          marginLeft: "2.5rem",
                          padding: "0.5rem",
                        }}
                      >
                        {subMenu.label}
                      </Typography>
                    </Link>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
};

export default Layout;
