import React from "react";
import StudentListTableComponent from "../../components/Dashboard/StudentListTableComponent/StudentListTableComponent";
import Loader from "../../components/Loader";
import { getStudentList } from "../../custom-hooks/client";

function AlumniStudentList() {
  const { isLoading, data: resp } = getStudentList(13);

  if (isLoading) {
    return <Loader />;
  }

  return <StudentListTableComponent rows={resp} excludeCols={["class"]} />;
}

export default AlumniStudentList;
