import React from "react";
import AuthValidator from "../../components/AuthValidator";
import DashboardComponent from "../../components/Dashboard/DashboardComponent";
import GenericHeader from "../../components/GenericHeader";
import Layout from "../../components/Layout/Layout";

const Dashboard = () => {
  return (
    <AuthValidator>
      <GenericHeader title="Dashboard" />
      <Layout>
        <DashboardComponent />
      </Layout>
    </AuthValidator>
  );
};

export default Dashboard;
