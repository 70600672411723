import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { addUser } from "../../custom-hooks/administration";
import { getCurrentUser } from "../../custom-hooks/client";
import { getMobileBool } from "../../custom-hooks/mediaHooks";
import { COLOR_THEME, ROUTES, USER_ROLES } from "../../utils/constants";

function RegisterUsers() {
  const registerUserMutation = addUser();
  const navigate = useNavigate();
  const isMobile = getMobileBool();
  const loggedInUser = getCurrentUser();

  useEffect(() => {
    if (registerUserMutation?.isError) {
    }
    if (registerUserMutation?.isSuccess) {
      navigate(ROUTES.DASHBOARD_USER_LIST);
    }
  }, [registerUserMutation?.isError, registerUserMutation?.isSuccess]);

  useEffect(() => {
    if (loggedInUser?.role !== "ADMIN") {
      console.log("here");
      navigate(ROUTES.NOT_AUTHORISED);
    }
  });

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        role: "",
        classes: "",
        email: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required("First Name is required"),
        last_name: Yup.string().required("Last Name is required"),
        role: Yup.string().required("Choose one role"),
        classes: Yup.string().when(["role"], {
          is: (role) => role === USER_ROLES.TEACHER,
          then: Yup.string().required("Assign one class"),
        }),
        email: Yup.string()
          .email()
          .required("Email is required for sign in credentials"),
        password: Yup.string().required(
          "Password is required for signin credentials"
        ),
      })}
      onSubmit={(values) => {
        const data = {
          name: values.first_name + " " + values.last_name,
          role: values.role,
          classes: [parseInt(`${values.classes}`)],
          email: values.email,
          password: values.password,
        };
        registerUserMutation.mutate(data);
      }}
    >
      {(formProps) => (
        <form style={{ marginTop: "2rem" }} onSubmit={formProps.handleSubmit}>
          <Box
            display="flex"
            sx={{ mb: 3 }}
            style={{ flexDirection: !isMobile ? "row" : "column" }}
          >
            <Box sx={{ mr: 10, ...(isMobile && { mb: 2 }) }}>
              <InputLabel style={{ color: COLOR_THEME.dark }}>
                First Name
              </InputLabel>
              <TextField
                name="first_name"
                value={formProps.values.first_name}
                placeholder="First Name"
                size="small"
                style={{ width: isMobile ? "20rem" : "25rem" }}
                onChange={formProps.handleChange}
              />
              {formProps.touched.first_name && formProps.errors.first_name && (
                <Typography sx={{ color: "red" }}>
                  {formProps.errors.first_name}
                </Typography>
              )}
            </Box>
            <Box>
              <InputLabel style={{ color: COLOR_THEME.dark }}>
                Last Name
              </InputLabel>
              <TextField
                name="last_name"
                value={formProps.values.last_name}
                placeholder="Last Name"
                size="small"
                style={{ width: isMobile ? "20rem" : "25rem" }}
                onChange={formProps.handleChange}
              />
              {formProps.touched.last_name && formProps.errors.last_name && (
                <Typography sx={{ color: "red" }}>
                  {formProps.errors.last_name}
                </Typography>
              )}
            </Box>
          </Box>
          <InputLabel style={{ color: COLOR_THEME.dark }} sx={{ mt: 2 }}>
            Role
          </InputLabel>
          <Select
            name="role"
            size="small"
            value={formProps.values.role}
            style={{ width: isMobile ? "20rem" : "25rem" }}
            onChange={formProps.handleChange}
            renderValue={(selected) => <Typography>{selected}</Typography>}
          >
            {Object.values(USER_ROLES).map((role) => (
              <MenuItem value={role} key={role}>
                <Checkbox
                  size="small"
                  checked={formProps.values.role === role}
                />
                <ListItemText primary={role.replace("_", " ")} />
              </MenuItem>
            ))}
          </Select>
          {formProps.touched.role && formProps.errors.role && (
            <Typography sx={{ color: "red" }}>
              {formProps.errors.role}
            </Typography>
          )}
          {formProps.values.role === USER_ROLES.TEACHER && (
            <>
              <InputLabel style={{ color: COLOR_THEME.dark }} sx={{ mt: 2 }}>
                Class
              </InputLabel>
              <Select
                name="classes"
                size="small"
                style={{ width: isMobile ? "20rem" : "25rem" }}
                value={formProps.values.classes}
                onChange={formProps.handleChange}
                renderValue={(selected) => (
                  <Typography>
                    {selected === -2 && "Nursery"}
                    {selected === -1 && "L.K.G"}
                    {selected === 0 && "U.K.G"}
                    {![-2, -1, 0].includes(selected) && `Class ${selected}`}
                  </Typography>
                )}
              >
                {[
                  { label: "Nursery", value: -2 },
                  { label: "L.K.G", value: -1 },
                  { label: "U.K.G", value: 0 },
                  ...new Array(12).fill().map((_, index) => ({
                    label: `Class ${index + 1}`,
                    value: index + 1,
                  })),
                ].map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    <Checkbox
                      size="small"
                      checked={formProps.values.classes === item.value}
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </Select>
              {formProps.touched.classes && formProps.errors.classes && (
                <Typography sx={{ color: "red" }}>
                  {formProps.errors.classes}
                </Typography>
              )}
            </>
          )}
          <InputLabel style={{ color: COLOR_THEME.dark }} sx={{ mt: 2 }}>
            Email
          </InputLabel>
          <TextField
            name="email"
            value={formProps.values.email}
            placeholder="Email"
            size="small"
            style={{ width: isMobile ? "20rem" : "25rem" }}
            onChange={formProps.handleChange}
          />
          {formProps.touched.email && formProps.errors.email && (
            <Typography sx={{ color: "red" }}>
              {formProps.errors.email}
            </Typography>
          )}
          <InputLabel style={{ color: COLOR_THEME.dark }} sx={{ mt: 2 }}>
            Password
          </InputLabel>
          <TextField
            name="password"
            value={formProps.values.password}
            placeholder="Password"
            size="small"
            style={{ width: isMobile ? "20rem" : "25rem" }}
            onChange={formProps.handleChange}
          />
          {formProps.touched.password && formProps.errors.password && (
            <Typography sx={{ color: "red" }}>
              {formProps.errors.password}
            </Typography>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: COLOR_THEME.header, mr: 2 }}
              onClick={formProps.handleSubmit}
            >
              {formProps.isSubmitting ? (
                <CircularProgress size={24} style={{ fill: "#FFF" }} />
              ) : (
                "Save"
              )}
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: COLOR_THEME.gray }}
              onClick={formProps.resetForm}
            >
              Clear
            </Button>
          </Box>
          {registerUserMutation?.isError && (
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                component="span"
                style={{
                  color: "red",
                  textAlign: "center",
                  padding: "0.5rem 1rem",
                  backgroundColor: "rgba(255, 0, 0, 0.2)",
                }}
              >
                {registerUserMutation?.error?.data ??
                  "Something went wrong. Try Again"}
              </Typography>
            </Box>
          )}
        </form>
      )}
    </Formik>
  );
}

export default RegisterUsers;
