import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./public-web/pages/Home";
import PublicRoutes from "./public-web/PublicRoutes";
import PortalRoutes from "./portal/PortalRoutes";

function Routing() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="public-web/*" element={<PublicRoutes />} />
        <Route path="portal/*" element={<PortalRoutes />} />
      </Routes>
    </BrowserRouter >
  )
}

export default Routing
