import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../utils/constants";

function NotAuth() {
  const navigate = useNavigate();
  return (
    <>
      <div>You are not authorised to access this page.</div>
      <Button onClick={() => navigate(ROUTES.DASHBOARD_STUDENT_LIST)}>
        Go Back to Dashboard
      </Button>
    </>
  );
}

export default NotAuth;
