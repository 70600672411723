import {
  Box,
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import StudentListTableComponent from "../../components/Dashboard/StudentListTableComponent/StudentListTableComponent";
import Loader from "../../components/Loader";
import { getCurrentUser, getStudentList } from "../../custom-hooks/client";
import { getMobileBool } from "../../custom-hooks/mediaHooks";
import { CLASS_LIST, COLOR_THEME, USER_ROLES } from "../../utils/constants";
import { getSelectedClass, queryClient } from "../../utils/helper";

function DetainStudentList() {
  const loggedInUser = getCurrentUser();
  const isMobile = getMobileBool();
  const selected_class =
    queryClient.getQueryData("dashboard-detain-class-select") ?? [];
  const [currentClass, setCurrentClasses] = useState(selected_class);
  const { isLoading, data: resp } = getStudentList(
    loggedInUser?.role === USER_ROLES.TEACHER
      ? loggedInUser.classes
      : currentClass
  );

  const detainStudents = useMemo(() => {
    return resp?.filter((row) => {
      return row.status === "DETAINED";
    });
  }, [resp]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {loggedInUser?.role !== USER_ROLES.TEACHER && (
        <Box
          style={{
            display: "flex",
            gap: "1rem",
            margin: "0.5rem 0",
            alignItems: "center",
          }}
        >
          <InputLabel sx={{ color: COLOR_THEME.dark }}>
            Select Class:
          </InputLabel>
          <Select
            name="classes"
            size="small"
            style={{
              width: isMobile ? "10rem" : "15rem",
            }}
            value={currentClass}
            onChange={(e) => {
              if (e.target.value[e.target.value.length - 1] === "all") {
                setCurrentClasses(CLASS_LIST.map((item) => item.value));
                queryClient.setQueryData(
                  "dashboard-detain-class-select",
                  CLASS_LIST.map((item) => item.value)
                );
              } else {
                setCurrentClasses(e.target.value);
                queryClient.setQueryData(
                  "dashboard-detain-class-select",
                  e.target.value
                );
              }
            }}
            renderValue={(selected) => {
              const classes = selected.map((classes) =>
                getSelectedClass(classes)
              );
              if (classes.length === 1) {
                return <Typography>{`Class ${classes[0]}`}</Typography>;
              }
              if (classes.length > 1) {
                return (
                  <Typography>{`Classes ${classes.join(", ")}`}</Typography>
                );
              }
              return "";
            }}
            multiple
          >
            <MenuItem key={"Select-All"} value={"all"}>
              <ListItemText primary={"Select All"} />
            </MenuItem>
            {loggedInUser?.role !== USER_ROLES.TEACHER &&
              CLASS_LIST.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  <Checkbox
                    size="small"
                    checked={currentClass.includes(item.value)}
                  />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
          </Select>
        </Box>
      )}
      <StudentListTableComponent rows={detainStudents} />
    </>
  );
}

export default DetainStudentList;
