/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccountBalanceWalletOutlined,
  Add,
  AnnouncementOutlined,
  ClassOutlined,
  CurrencyRupeeOutlined,
  Delete,
  ExpandMore,
  HomeOutlined,
  ListOutlined,
  LocalPhoneOutlined,
  ManOutlined,
  PersonOutlineOutlined,
  SafetyDivider,
  WomanOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { format } from "date-fns";
import { FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import GenericFeeReceiptPrint from "../../components/GenericFeeReceiptPrint";
import Loader from "../../components/Loader";
import {
  addStudent,
  deleteStudent,
  updateStudent,
} from "../../custom-hooks/administration";
import { getCurrentUser, getStudentData } from "../../custom-hooks/client";
import { getMobileBool } from "../../custom-hooks/mediaHooks";
import {
  CLASS_LIST,
  COLOR_THEME,
  GENDER_TYPE,
  ROUTES,
  USER_ROLES,
} from "../../utils/constants";
import { getSelectedClass, toDate } from "../../utils/helper";
import { useCompressAndConvertToBase64 } from "../../utils/useFileToBase64";

function AddStudent() {
  const registerStudentMutation = addStudent();
  const [isopenDialog, setisOpenDialog] = useState(false);
  const navigate = useNavigate();
  const isMobile = getMobileBool();
  const loggedInUser = getCurrentUser();
  const params = useParams();

  const update = !!(params?.studentId || params?.studentId === 0);
  const { isLoading, data } = getStudentData(params?.studentId);
  const updateStudentMutation = updateStudent(params?.studentId);
  const deleteStudentMutation = deleteStudent();

  const [edit, setEdit] = useState(update ? false : true);
  const [viewFee, setViewFee] = useState(false);
  const [feeTab, setFeeTab] = useState("current");
  const [promoteFee, setPromoteFee] = useState(false);
  const [promoteFeeAmt, setPromoteFeeAmt] = useState("0");
  const [showMorePrevFee, setShowMorePrevFee] = useState(false);

  const [convertToBase64, loadingImage] = useCompressAndConvertToBase64();

  useEffect(() => {
    if (registerStudentMutation?.isSuccess) {
      setisOpenDialog(true);
    }
  }, [registerStudentMutation?.isSuccess]);

  useEffect(() => {
    if (deleteStudentMutation?.isSuccess) {
      navigate(ROUTES.DASHBOARD_STUDENT_LIST);
    }
  }, [deleteStudentMutation?.isSuccess]);

  useEffect(() => {
    if (updateStudentMutation?.isSuccess) {
      setisOpenDialog(true);
      setPromoteFee(false);
    }
  }, [updateStudentMutation?.isSuccess]);

  if (isLoading) {
    <Loader />;
  }

  const currentMonth = new Date().getMonth() + 1;

  return !edit ? (
    <Box style={{ width: "100%" }}>
      <Grid
        display={"flex"}
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 1, pb: 1 }}
      >
        <Box display="flex" style={{ gap: "1rem" }}>
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            {viewFee ? "Fee Details" : "Student Details"}
          </Typography>
          {(viewFee || (data?.status !== "DETAINED" && data?.class !== 13)) && (
            <Button
              variant="contained"
              onClick={() => (viewFee ? setViewFee(false) : setEdit(true))}
            >
              {viewFee ? "Back" : "Edit Data"}
            </Button>
          )}
        </Box>
      </Grid>
      {!viewFee && (
        <>
          <Card>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection={isMobile ? "column-reverse" : "row"}
              sx={{ pr: 2 }}
            >
              <Box style={{ width: "100%" }}>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.25rem",
                    padding: "0.5rem 1rem",
                  }}
                >
                  <PersonOutlineOutlined
                    style={{ fill: COLOR_THEME.hover, fontSize: "2rem" }}
                  />{" "}
                  <b>Name:</b> {data?.name}
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "end",
                    gap: "0.25rem",
                    padding: "0.5rem 1rem",
                  }}
                >
                  <ManOutlined
                    style={{ fill: COLOR_THEME.hover, fontSize: "2rem" }}
                  />
                  <b>Father's Name:</b> {data?.father_name}
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "end",
                    gap: "0.25rem",
                    padding: "0.5rem 1rem",
                  }}
                >
                  <WomanOutlined
                    style={{ fill: COLOR_THEME.hover, fontSize: "2rem" }}
                  />{" "}
                  <b>Mother's Name:</b> {data?.mother_name ?? "N/A"}
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "end",
                    gap: "0.25rem",
                    padding: "0.5rem 1rem",
                  }}
                >
                  <ClassOutlined
                    style={{ fill: COLOR_THEME.hover, fontSize: "2rem" }}
                  />
                  <b>Class:</b> {getSelectedClass(data?.class)}
                </Typography>
              </Box>
              <Box sx={{ pt: 2 }}>
                {data?.image_url ? (
                  <img
                    src={data?.image_url}
                    alt={data?.name}
                    style={{
                      height: isMobile ? "10rem" : "12rem",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <Avatar
                    style={{
                      height: isMobile ? "10rem" : "12rem",
                      width: isMobile ? "10rem" : "12rem",
                    }}
                  />
                )}
              </Box>
            </Box>
            <Typography
              style={{
                display: "flex",
                alignItems: "end",
                gap: "0.25rem",
                padding: "0.5rem 1rem",
              }}
            >
              <SafetyDivider
                style={{ fill: COLOR_THEME.hover, fontSize: "2rem" }}
              />
              <b>Section:</b> {data?.section}
            </Typography>
            <Typography
              style={{
                display: "flex",
                alignItems: "end",
                gap: "0.25rem",
                padding: "0.5rem 1rem",
              }}
            >
              <ListOutlined
                style={{ fill: COLOR_THEME.hover, fontSize: "2rem" }}
              />
              <b> Gender:</b> {data?.gender}
            </Typography>
            <Typography
              style={{
                display: "flex",
                alignItems: "end",
                gap: "0.25rem",
                padding: "0.5rem 1rem",
              }}
            >
              <HomeOutlined
                style={{ fill: COLOR_THEME.hover, fontSize: "2rem" }}
              />
              <b>Address:</b> {data?.address}
            </Typography>
            <Typography
              style={{
                display: "flex",
                alignItems: "end",
                gap: "0.25rem",
                padding: "0.5rem 1rem",
              }}
            >
              <LocalPhoneOutlined
                style={{ fill: COLOR_THEME.hover, fontSize: "2rem" }}
              />
              <b>Mobile Number:</b> {data?.mobile_number ?? "N/A"}
            </Typography>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginRight: "2rem",
              }}
            >
              <Box>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "end",
                    gap: "0.25rem",
                    padding: "0.5rem 1rem",
                  }}
                >
                  <CurrencyRupeeOutlined
                    style={{ fill: COLOR_THEME.hover, fontSize: "2rem" }}
                  />
                  <b>Total Fee:</b> {data?.fees?.total_fees}
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "end",
                    gap: "0.25rem",
                    padding: "0.5rem 1rem",
                  }}
                >
                  <AccountBalanceWalletOutlined
                    style={{ fill: COLOR_THEME.hover, fontSize: "2rem" }}
                  />
                  <b>Balance Fee:</b> {data?.fees?.balance_fees}
                </Typography>
              </Box>
              <Button variant="contained" onClick={() => setViewFee(true)}>
                Fee Details
              </Button>
            </Box>
            <Typography
              style={{
                display: "flex",
                alignItems: "end",
                gap: "0.25rem",
                padding: "0.5rem 1rem",
              }}
            >
              <AnnouncementOutlined
                style={{ fill: COLOR_THEME.hover, fontSize: "2rem" }}
              />
              <b>Previous Balance Fee:</b>{" "}
              {data?.fees?.prev_balance_fees ?? "0"}
            </Typography>
          </Card>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Box>
              {[3, 4, 5, 6, 7, 8].includes(currentMonth) && (
                <>
                  {data?.class !== 13 && (
                    <Button
                      variant="contained"
                      onClick={() => setPromoteFee(true)}
                    >
                      Promote
                    </Button>
                  )}
                  {data?.status !== "DETAINED" && data?.class !== 13 && (
                    <Button
                      sx={{ ml: 1 }}
                      variant="contained"
                      onClick={() => {
                        let dataObj = {
                          ...data,
                          total_fees: 0,
                          balance_fees: 0,
                          fee_details: JSON.stringify([]),
                          prev_total_fees:
                            (data?.fees?.prev_balance_fees ??
                            0) + (data?.fees?.balance_fees ??
                            0),
                          prev_balance_fees:
                            (data?.fees?.prev_balance_fees ??
                            0) + (data?.fees?.balance_fees ??
                            0),
                          status: "DETAINED",
                          prev_fee_details: JSON.stringify([]),
                          rest_prev_fee_details: JSON.stringify(
                            [
                              ...(data?.fees?.rest_prev_fee_details ?? []),
                              ...(data?.fees?.prev_fee_details ?? []),
                              ...(data?.fees?.fee_details ?? []),
                            ].sort((a, b) => {
                              const _a = toDate(a?.date);
                              const _b = toDate(b?.date);
                              return _a.getTime() - _b.getTime();
                            }) ?? []
                          ),
                        };
                        updateStudentMutation.mutate(dataObj);
                        return null;
                      }}
                    >
                      Detain
                    </Button>
                  )}
                </>
              )}
            </Box>
            {loggedInUser?.role !== USER_ROLES.TEACHER && (
              <Button
                variant="contained"
                onClick={() => deleteStudentMutation.mutate(params?.studentId)}
              >
                Delete
              </Button>
            )}
          </Box>
          <Dialog onClose={() => setPromoteFee(false)} open={promoteFee}>
            <DialogTitle style={{ textAlign: "center" }}>
              New Total Fees
            </DialogTitle>
            <Box
              sx={{ p: 2 }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                value={promoteFeeAmt}
                onChange={(e) => setPromoteFeeAmt(e.target.value)}
              />
              <Button
                variant="contained"
                sx={{ backgroundColor: COLOR_THEME.gray }}
                onClick={() => {
                  let dataObj = {
                    ...data,
                    class: data?.class + 1,
                    total_fees: parseInt(promoteFeeAmt ?? 0),
                    balance_fees: parseInt(promoteFeeAmt),
                    fee_details: JSON.stringify([]),
                    prev_total_fees:
                      (data?.fees?.prev_balance_fees ?? 0) +
                      (data?.fees?.balance_fees ?? 0),
                    prev_balance_fees:
                      (data?.fees?.prev_balance_fees ?? 0) +
                      (data?.fees?.balance_fees ?? 0),
                    status: "PROMOTED",
                    prev_fee_details: JSON.stringify([]),
                    rest_prev_fee_details: JSON.stringify(
                      [
                        ...(data?.fees?.rest_prev_fee_details ?? []),
                        ...(data?.fees?.prev_fee_details ?? []),
                        ...(data?.fees?.fee_details ?? []),
                      ].sort((a, b) => {
                        const _a = toDate(a?.date);
                        const _b = toDate(b?.date);
                        return _a.getTime() - _b.getTime();
                      }) ?? []
                    ),
                  };
                  updateStudentMutation.mutate(dataObj);
                  return null;
                }}
              >
                {update ? "OK" : "No"}
              </Button>
            </Box>
          </Dialog>
        </>
      )}
      {viewFee && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={feeTab} onChange={(_, val) => setFeeTab(val)}>
              <Tab value="current" label="Current Fee" />
              <Tab value="previous" label="Previous Fee" />
            </Tabs>
          </Box>
          <div role="tabpanel" hidden={feeTab !== "current"}>
            {feeTab === "current" && (
              <Card style={{ padding: "1rem" }}>
                {data?.fees?.fee_details?.length ? (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Date</Typography>
                      <Typography>Amount</Typography>
                    </Box>
                    {loggedInUser?.role !== USER_ROLES.ADMIN &&
                      data?.fees?.fee_details?.map(
                        ({ date, amount }, index) => (
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            key={index}
                          >
                            <Typography>{date}</Typography>
                            <Typography>{amount}</Typography>
                          </Box>
                        )
                      )}
                  </Box>
                ) : (
                  <Typography>No Fee Details Found</Typography>
                )}
                {loggedInUser?.role === USER_ROLES.ADMIN && (
                  <Formik
                    initialValues={{
                      fee_details:
                        data?.fees?.fee_details?.map((item) => ({
                          date: toDate(item?.date),
                          amount: item?.amount,
                        })) ?? [],
                    }}
                    onSubmit={(values, { resetForm }) => {
                      let sum_fee = 0;
                      let fee_details = values.fee_details?.map((item) => {
                        sum_fee += parseInt(item?.amount ?? 0);
                        return {
                          date: format(item.date, "dd-MM-yyyy"),
                          amount: parseInt(item.amount ?? 0),
                        };
                      });
                      let fee_obj = {
                        ...data,
                        ...data.fees,
                        balance_fees:
                          parseInt(data?.fees?.total_fees) - parseInt(sum_fee),
                        fee_details: JSON.stringify(fee_details),
                        prev_fee_details: JSON.stringify(
                          data?.fees?.prev_fee_details
                        ),
                        rest_prev_fee_details: JSON.stringify(
                          data?.fees?.rest_prev_fee_details
                        ),
                      };
                      updateStudentMutation.mutate(fee_obj);
                      resetForm();
                    }}
                    enableReinitialize
                  >
                    {(formProps) => (
                      <FieldArray
                        name="fee_details"
                        render={(arrayHelpers) => (
                          <Box>
                            {formProps.values.fee_details &&
                              formProps.values.fee_details?.map(
                                (detail, index) => (
                                  <Box
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      margin: "1rem 0",
                                    }}
                                  >
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: isMobile
                                          ? "column"
                                          : "row",
                                        gap: "1rem",
                                        justifyContent: "space-between",
                                        marginRight: "1rem",
                                      }}
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <MobileDatePicker
                                          label="Submission Date"
                                          name={`fee_details[${index}].date`}
                                          value={
                                            formProps.values.fee_details[index]
                                              ?.date
                                          }
                                          inputFormat="dd-MM-yyyy"
                                          onChange={(newValue) =>
                                            formProps.setFieldValue(
                                              `fee_details[${index}].date`,
                                              newValue
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              size="small"
                                              {...params}
                                            />
                                          )}
                                        />
                                      </LocalizationProvider>
                                      <TextField
                                        name={`fee_details[${index}].amount`}
                                        value={
                                          formProps.values.fee_details[index]
                                            ?.amount
                                        }
                                        placeholder="Amount"
                                        size="small"
                                        style={{
                                          width: isMobile ? "13rem" : "25rem",
                                        }}
                                        onChange={(e) =>
                                          formProps.setFieldValue(
                                            `fee_details[${index}].amount`,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Box>
                                    <Delete
                                      style={{ fill: COLOR_THEME.primary }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </Box>
                                )
                              )}
                            <Add
                              style={{
                                fill: COLOR_THEME.primary,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                arrayHelpers.push({
                                  date: new Date(),
                                  amount: "0",
                                })
                              }
                            />
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={formProps.handleSubmit}
                              >
                                Submit
                              </Button>
                              <Button
                                variant="contained"
                                onClick={formProps.resetForm}
                              >
                                Clear
                              </Button>
                            </div>
                          </Box>
                        )}
                      />
                    )}
                  </Formik>
                )}
              </Card>
            )}
          </div>
          <div role="tabpanel" hidden={feeTab !== "previous"}>
            {feeTab === "previous" && (
              <Card style={{ padding: "1rem" }}>
                <>
                  {data?.fees?.rest_prev_fee_details?.length > 0 && (
                    <Accordion
                      expanded={showMorePrevFee}
                      onChange={() => setShowMorePrevFee((p) => !p)}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        More Previous Fee Records
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>Date</Typography>
                            <Typography>Amount</Typography>
                          </Box>
                          {data?.fees?.rest_prev_fee_details?.map(
                            ({ date, amount }, index) => (
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                key={index}
                              >
                                <Typography>{date}</Typography>
                                <Typography>{amount}</Typography>
                              </Box>
                            )
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {data?.fees?.prev_fee_details?.length ? (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>Date</Typography>
                        <Typography>Amount</Typography>
                      </Box>
                      {loggedInUser?.role !== USER_ROLES.ADMIN &&
                        data?.fees?.prev_fee_details?.map(
                          ({ date, amount }, index) => (
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={index}
                            >
                              <Typography>{date}</Typography>
                              <Typography>{amount}</Typography>
                            </Box>
                          )
                        )}
                    </Box>
                  ) : (
                    <Typography>No Previous Year Fee Details Found</Typography>
                  )}
                </>
                {loggedInUser?.role === USER_ROLES.ADMIN && (
                  <Formik
                    initialValues={{
                      prev_fee_details:
                        data?.fees?.prev_fee_details?.map((item) => ({
                          date: toDate(item?.date),
                          amount: item?.amount,
                        })) ?? [],
                    }}
                    onSubmit={(values, { resetForm }) => {
                      let sum_fee = 0;
                      let prev_fee_details = values.prev_fee_details?.map(
                        (item) => {
                          sum_fee += parseInt(item?.amount ?? 0);
                          return {
                            date: format(item.date, "dd-MM-yyyy"),
                            amount: parseInt(item.amount ?? 0),
                          };
                        }
                      );
                      let fee_obj = {
                        ...data,
                        ...data.fees,
                        prev_balance_fees:
                          parseInt(data?.fees?.prev_total_fees ?? 0) -
                          parseInt(sum_fee ?? 0),
                        fee_details: JSON.stringify(
                          data?.fees?.fee_details ?? []
                        ),
                        prev_fee_details: JSON.stringify(
                          prev_fee_details ?? []
                        ),
                        rest_prev_fee_details: JSON.stringify(
                          data?.fees?.rest_prev_fee_details ?? []
                        ),
                      };
                      updateStudentMutation.mutate(fee_obj);
                      resetForm();
                    }}
                    enableReinitialize
                  >
                    {(formProps) => (
                      <FieldArray
                        name="prev_fee_details"
                        render={(arrayHelpers) => (
                          <Box>
                            {formProps.values.prev_fee_details &&
                              formProps.values.prev_fee_details?.map(
                                (detail, index) => (
                                  <Box
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      margin: "1rem 0",
                                    }}
                                  >
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: isMobile
                                          ? "column"
                                          : "row",
                                        gap: "1rem",
                                        justifyContent: "space-between",
                                        marginRight: "1rem",
                                      }}
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <MobileDatePicker
                                          label="Submission Date"
                                          name={`prev_fee_details[${index}].date`}
                                          value={
                                            formProps.values.prev_fee_details[
                                              index
                                            ]?.date
                                          }
                                          inputFormat="dd-MM-yyyy"
                                          onChange={(newValue) =>
                                            formProps.setFieldValue(
                                              `prev_fee_details[${index}].date`,
                                              newValue
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              size="small"
                                              {...params}
                                            />
                                          )}
                                        />
                                      </LocalizationProvider>
                                      <TextField
                                        name={`prev_fee_details[${index}].amount`}
                                        value={
                                          formProps.values.prev_fee_details[
                                            index
                                          ]?.amount
                                        }
                                        placeholder="Amount"
                                        size="small"
                                        style={{
                                          width: isMobile ? "13rem" : "25rem",
                                        }}
                                        onChange={(e) =>
                                          formProps.setFieldValue(
                                            `prev_fee_details[${index}].amount`,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Box>
                                    <Delete
                                      style={{ fill: COLOR_THEME.primary }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </Box>
                                )
                              )}
                            <Add
                              style={{
                                fill: COLOR_THEME.primary,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                arrayHelpers.push({
                                  date: new Date(),
                                  amount: "0",
                                })
                              }
                            />
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                                marginTop: "1rem",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={formProps.handleSubmit}
                              >
                                Submit
                              </Button>
                              <Button
                                variant="contained"
                                onClick={formProps.resetForm}
                              >
                                Clear
                              </Button>
                              {feeTab === "previous" && (
                                <Button variant="contained" onClick={() => {
                                  const text = "Are you sure you want to final the previous settlement?";
                                  // eslint-disable-next-line no-restricted-globals
                                  if(confirm(text) === true) {
                                    let fee_obj = {
                                      ...data,
                                      ...data.fees,
                                      fee_details: JSON.stringify(
                                        data?.fees?.fee_details ?? []
                                      ),
                                      prev_fee_details: JSON.stringify(
                                        data?.fees?.prev_fee_details ?? []
                                      ),
                                      prev_balance_fees: 0,
                                      prev_total_fees: data?.fees?.prev_balance_fees,
                                      rest_prev_fee_details: JSON.stringify(
                                        data?.fees?.rest_prev_fee_details ?? []
                                      ),
                                    };
                                    updateStudentMutation.mutate(fee_obj);
                                  }
                                  }}>Final Settlement</Button>
                                )}
                            </div>
                          </Box>
                        )}
                      />
                    )}
                  </Formik>
                )}
              </Card>
            )}
          </div>
          <GenericFeeReceiptPrint data={data} feeTab={feeTab}/>
        </>
      )}
    </Box>
  ) : (
    <Formik
      initialValues={{
        name: data?.name ?? "",
        father_name: data?.father_name ?? "",
        mother_name: data?.mother_name ?? "",
        class_no:
          data?.class ??
          (loggedInUser?.role === USER_ROLES.TEACHER
            ? loggedInUser?.classes[0]
            : ""),
        image_url: data?.image_url ?? "",
        section: data?.section ?? "",
        gender: data?.gender ?? "",
        address: data?.address ?? "",
        mobile_number: data?.mobile_number ?? "",
        total_fee: data?.fees?.total_fees ?? "0",
        file: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is required"),
        father_name: Yup.string().required("Father's Name is required"),
        mother_name: Yup.string().when("class_no", {
          is: (class_no) => class_no > 8,
          then: Yup.string().required("Mother's Name is required"),
        }),
        gender: Yup.string().required("Gender is required"),
        class_no: Yup.number().required("Class is required"),
        address: Yup.string().required("Address is required"),
        mobile_number: Yup.string()
          .test(
            "Digits only",
            "The field should have digits only",
            (value) => !value?.length || /^\d+$/.test(value)
          )
          .test(
            "len",
            "Mobile number should have 10 characters",
            (value) => !value?.length || value?.length === 10
          ),
        total_fee: Yup.string()
          .required("Total Fee is required")
          .test("Digits only", "The field should have digits only", (value) =>
            /^\d+$/.test(value)
          ),
      })}
      onSubmit={(values) => {
        const submittedFees =
          data?.fees?.fee_details?.reduce(
            (sum, item) => sum + parseInt(item?.amount ?? 0),
            0
          ) ?? 0;

        const prevSubmittedFees =
          data?.fees?.prev_fee_details?.reduce(
            (sum, item) => sum + parseInt(item?.amount ?? 0),
            0
          ) ?? 0;

        let dataObj = {
          name: values.name,
          class: values.class_no,
          section: values.section,
          father_name: values.father_name,
          mother_name: values.mother_name,
          address: values.address,
          gender: values.gender,
          status: data?.status ?? "NEW",
          mobile_number: values.mobile_number ?? "",
          image_url: values.image_url || "",
          total_fees: parseInt(values.total_fee),
          balance_fees: parseInt(values.total_fee) - submittedFees,
          fee_details: JSON.stringify(data?.fees?.fee_details ?? []),
          prev_total_fees: parseInt(data?.fees?.prev_total_fees ?? 0),
          prev_balance_fees:
            parseInt(data?.fees?.prev_total_fees ?? 0) - prevSubmittedFees,
          prev_fee_details: JSON.stringify(data?.fees?.prev_fee_details ?? []),
          file: "",
        };
        if (update) {
          updateStudentMutation.mutate(dataObj);
          return;
        }
        registerStudentMutation.mutate(dataObj);
      }}
      enableReinitialize
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            {update ? "Update Student" : "Add New Student"}
          </Typography>
          {formProps.values.image_url && <Box><img src={formProps.values.image_url} style={{height: "150px"}} alt="uploaded_pic"/></Box>}
          <Box
            display="flex"
            sx={{ mb: 2, ...(isMobile && { mt: 2 }) }}
            style={{ flexDirection: !isMobile ? "row" : "column-reverse" }}
          >
            <Box sx={{ mr: 10 }}>
              <InputLabel style={{ color: COLOR_THEME.dark }}>Name</InputLabel>
              <TextField
                name="name"
                value={formProps.values.name}
                placeholder="Name"
                size="small"
                style={{ width: isMobile ? "20rem" : "25rem" }}
                onChange={formProps.handleChange}
              />
              {formProps.touched.name && formProps.errors.name && (
                <Typography sx={{ color: "red" }}>
                  {formProps.errors.name}
                </Typography>
              )}
            </Box>
            <Box sx={{ mb: 2 }}>
              <>
                <InputLabel style={{ color: COLOR_THEME.dark }}>
                  {data?.image_url ? "Update " : "Upload "}Image
                </InputLabel>
                <input
                  type="file"
                  name="file"
                  onChange={async (event) => {
                    formProps.setFieldValue(
                      "image_url",
                      await convertToBase64(event.currentTarget.files[0])
                    );
                  }}
                />
              </>
            </Box>
          </Box>
          <Box
            display="flex"
            style={{ flexDirection: !isMobile ? "row" : "column" }}
            >
            <Box sx={{ mr: 10, ...(isMobile && { mb: 2 }) }}>
              <InputLabel style={{ color: COLOR_THEME.dark }}>
                Father's Name
              </InputLabel>
              <TextField
                name="father_name"
                value={formProps.values.father_name}
                placeholder="Father's Name"
                size="small"
                style={{ width: isMobile ? "20rem" : "25rem" }}
                onChange={formProps.handleChange}
              />
              {formProps.touched.father_name &&
                formProps.errors.father_name && (
                  <Typography sx={{ color: "red" }}>
                    {formProps.errors.father_name}
                  </Typography>
                )}
            </Box>
            <Box>
              <InputLabel style={{ color: COLOR_THEME.dark }}>
                Mother's Name
              </InputLabel>
              <TextField
                name="mother_name"
                value={formProps.values.mother_name}
                placeholder="Mother's Name"
                size="small"
                style={{ width: isMobile ? "20rem" : "25rem" }}
                onChange={formProps.handleChange}
              />
              {formProps.touched.mother_name &&
                formProps.errors.mother_name && (
                  <Typography sx={{ color: "red" }}>
                    {formProps.errors.mother_name}
                  </Typography>
                )}
            </Box>
          </Box>
          <Box
            display="flex"
            sx={{ mt: 2 }}
            style={{ flexDirection: !isMobile ? "row" : "column" }}
          >
            <Box sx={{ mr: 10, ...(isMobile && { mb: 2 }) }}>
              <InputLabel style={{ color: COLOR_THEME.dark }}>Class</InputLabel>
              <Select
                name="class_no"
                size="small"
                style={{ width: isMobile ? "20rem" : "25rem" }}
                value={formProps.values.class_no}
                onChange={formProps.handleChange}
                renderValue={(selected) => (
                  <Typography>
                    {selected === -2 && "Nursery"}
                    {selected === -1 && "L.K.G"}
                    {selected === 0 && "U.K.G"}
                    {![-2, -1, 0].includes(selected) && `Class ${selected}`}
                  </Typography>
                )}
              >
                {loggedInUser.role !== USER_ROLES.TEACHER &&
                  CLASS_LIST.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      <Checkbox
                        size="small"
                        checked={formProps.values.class_no === item.value}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  ))}
              </Select>
              {formProps.touched.class_no && formProps.errors.class_no && (
                <Typography sx={{ color: "red" }}>
                  {formProps.errors.class_no}
                </Typography>
              )}
            </Box>
            <Box>
              <InputLabel style={{ color: COLOR_THEME.dark }}>
                Section
              </InputLabel>
              <TextField
                name="section"
                value={formProps.values.section}
                placeholder="Section"
                size="small"
                style={{ width: isMobile ? "20rem" : "25rem" }}
                onChange={formProps.handleChange}
              />
              {formProps.touched.section && formProps.errors.section && (
                <Typography sx={{ color: "red" }}>
                  {formProps.errors.section}
                </Typography>
              )}
            </Box>
          </Box>
          <InputLabel style={{ color: COLOR_THEME.dark }} sx={{ mt: 2 }}>
            Gender
          </InputLabel>
          <Select
            name="gender"
            size="small"
            style={{ width: isMobile ? "20rem" : "25rem" }}
            value={formProps.values.gender}
            onChange={formProps.handleChange}
            renderValue={(selected) => <Typography>{selected}</Typography>}
          >
            {Object.values(GENDER_TYPE).map((item) => (
              <MenuItem value={item} key={item}>
                <Checkbox
                  size="small"
                  checked={formProps.values.gender === item}
                />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
          {formProps.touched.gender && formProps.errors.gender && (
            <Typography sx={{ color: "red" }}>
              {formProps.errors.gender}
            </Typography>
          )}
          <Box
            display="flex"
            sx={{ mt: 2 }}
            style={{ flexDirection: !isMobile ? "row" : "column" }}
          >
            <Box sx={{ mr: 10, ...(isMobile && { mb: 2 }) }}>
              <InputLabel style={{ color: COLOR_THEME.dark }}>
                Mobile Number
              </InputLabel>
              <TextField
                name="mobile_number"
                value={formProps.values.mobile_number}
                placeholder="Mobile Number"
                size="small"
                style={{ width: isMobile ? "20rem" : "25rem" }}
                onChange={formProps.handleChange}
              />
              {formProps.touched.mobile_number &&
                formProps.errors.mobile_number && (
                  <Typography sx={{ color: "red" }}>
                    {formProps.errors.mobile_number}
                  </Typography>
                )}
            </Box>
            <Box>
              <InputLabel style={{ color: COLOR_THEME.dark }}>
                Address
              </InputLabel>
              <TextField
                name="address"
                value={formProps.values.address}
                placeholder="Address"
                size="small"
                style={{ width: isMobile ? "20rem" : "25rem" }}
                onChange={formProps.handleChange}
              />
              {formProps.touched.address && formProps.errors.address && (
                <Typography sx={{ color: "red" }}>
                  {formProps.errors.address}
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            sx={{ mt: 2 }}
            style={{ flexDirection: !isMobile ? "row" : "column" }}
          >
            <Box sx={{ mr: 10, ...(isMobile && { mb: 2 }) }}>
              <InputLabel style={{ color: COLOR_THEME.dark }}>
                Total Fees
              </InputLabel>
              <TextField
                name="total_fee"
                value={formProps.values.total_fee}
                placeholder="Total Fees"
                size="small"
                style={{ width: isMobile ? "20rem" : "25rem" }}
                onChange={formProps.handleChange}
                disabled={update && loggedInUser?.role !== USER_ROLES.ADMIN}
              />
              {formProps.touched.total_fee && formProps.errors.total_fee && (
                <Typography sx={{ color: "red" }}>
                  {formProps.errors.total_fee}
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: COLOR_THEME.header, mr: 2 }}
              onClick={formProps.handleSubmit}
            >
              {formProps.isSubmitting&& loadingImage ? (
                <CircularProgress size={24} style={{ fill: "#FFF" }} />
              ) : (
                "Save"
              )}
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: COLOR_THEME.gray }}
              onClick={formProps.resetForm}
            >
              Clear
            </Button>
          </Box>
          {registerStudentMutation?.isError && (
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                component="span"
                style={{
                  color: "red",
                  textAlign: "center",
                  padding: "0.5rem 1rem",
                  backgroundColor: "rgba(255, 0, 0, 0.2)",
                }}
              >
                {registerStudentMutation?.error?.data ??
                  "Something went wrong. Try Again"}
              </Typography>
            </Box>
          )}
          <Dialog onClose={() => setisOpenDialog(false)} open={isopenDialog}>
            {!update ? (
              <DialogTitle style={{ textAlign: "center" }}>
                Student Added Successfully. <br />
                Add another Student?
              </DialogTitle>
            ) : (
              <DialogTitle style={{ textAlign: "center" }}>
                Student Updated Successfully.
              </DialogTitle>
            )}
            <Box
              sx={{ mt: 2, mb: 2 }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {!update && (
                <Button
                  variant="contained"
                  sx={{ backgroundColor: COLOR_THEME.header, mr: 2 }}
                  onClick={() => {
                    setisOpenDialog(false);
                    formProps.resetForm();
                  }}
                >
                  Yes
                </Button>
              )}
              <Button
                variant="contained"
                sx={{ backgroundColor: COLOR_THEME.gray }}
                onClick={() => {
                  setisOpenDialog(false);
                  navigate(ROUTES.DASHBOARD_STUDENT_LIST);
                }}
              >
                {update ? "OK" : "No"}
              </Button>
            </Box>
          </Dialog>
        </form>
      )}
    </Formik>
  );
}

export default AddStudent;
