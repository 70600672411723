import React, { useEffect, useState } from 'react';
import { queryClient } from "../utils/helper";
import { ROUTES } from "../utils/constants";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';

const PUBLIC_ROUTES = [ROUTES.LOGIN];

const Main = () => {
    const navigate = useNavigate(); 
    const location = useLocation();
    const isPublicRoute = PUBLIC_ROUTES.includes(location?.pathname);
    const currentUser = queryClient.getQueryData(["currentUser"]);

    const [isAuthenticated, setIsAuthenticated] = useState(
        isPublicRoute || !!currentUser?.email
    );

    useEffect(() => {
        const localUser = JSON.parse(localStorage.getItem("user"));
        const isLogged = isAuthenticated || localUser;
        !isLogged && navigate(ROUTES.LOGIN);
        isLogged && navigate(ROUTES.DASHBOARD);
        queryClient.setQueryData(["currentUser"], localUser);
        setIsAuthenticated(!!localUser?.email);
    }, [isAuthenticated]);

    return <Loader />
}

export default Main;
