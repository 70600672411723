import React from "react";
import AuthValidator from "../../components/AuthValidator";
import GenericHeader from "../../components/GenericHeader";
import Layout from "../../components/Layout/Layout";

function ViewResults() {
  return (
    <AuthValidator>
      <GenericHeader title="View Results" />
      <Layout>
        <div>ViewResults</div>
      </Layout>
    </AuthValidator>
  );
}

export default ViewResults;
