import React from "react";
import AuthValidator from "../../components/AuthValidator";
import GenericHeader from "../../components/GenericHeader";
import Layout from "../../components/Layout/Layout";

function UploadMarks() {
  return (
    <AuthValidator>
      <GenericHeader title="Upload Marks" />
      <Layout>
        <div>UploadMarks</div>
      </Layout>
    </AuthValidator>
  );
}

export default UploadMarks;
