import React from "react";
import AuthValidator from "../../components/AuthValidator";
import GenericHeader from "../../components/GenericHeader";
import Layout from "../../components/Layout/Layout";

const Results = () => {
  return (
    <AuthValidator>
      <GenericHeader title="Results" />
      <Layout>
        <div>Results</div>
      </Layout>
    </AuthValidator>
  );
};

export default Results;
