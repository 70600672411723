import { Box, Checkbox, FormControlLabel, InputLabel, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { getStudentList } from '../../custom-hooks/client';
import { getMobileBool } from '../../custom-hooks/mediaHooks';
import { CLASS_LIST, COLOR_THEME } from '../../utils/constants';
import { getSelectedClass } from '../../utils/helper';
import GenericPrintList from '../GenericPrintList';
import Loader from '../Loader';

const PrintLists = () => {
  const isMobile = getMobileBool();
  const [currentClass, setCurrentClasses] = useState("");
  const [sortByFees, setSortByFees] = useState(true);
  const { isLoading, data: resp } = getStudentList(currentClass);

  const presentStudents = useMemo(() => {
    return resp?.filter((row) => {
      return row.status !== "DETAINED";
    }).sort((a, b) => {
        if (!sortByFees) return a.name.localeCompare(b.name);
        return b.fees.balance_fees - a.fees.balance_fees
    });
  }, [resp, sortByFees]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
    <Box
          style={{
            display: "flex",
            gap: "1rem",
            margin: "0.5rem 0",
            alignItems: "center",
          }}
        >
          <InputLabel sx={{ color: COLOR_THEME.dark }}>
            Select Class:
          </InputLabel>
          <Select
            name="classes"
            size="small"
            style={{
              width: isMobile ? "10rem" : "15rem",
            }}
            value={currentClass}
            onChange={(e) => {
                setCurrentClasses(e.target.value);
            }}
            renderValue={(selected) => {
              const className = getSelectedClass(selected);
              if (className) {
                return <Typography>{`Class ${className}`}</Typography>;
              }
              return "";
            }}
          >
            {CLASS_LIST.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  <Checkbox
                    size="small"
                    checked={currentClass === item.value}
                  />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
          </Select>
          <FormControlLabel
                    checked={sortByFees}
                    onChange={() =>
                        setSortByFees((p) => !p)
                    }
                    control={<Checkbox />}
                    label="Sort by Fees"
                    />
        </Box>
      {getSelectedClass(currentClass) && <GenericPrintList list={presentStudents} title={getSelectedClass(currentClass)}/>}
    </>)
}

export default PrintLists