import React from "react";
import AuthValidator from "../../components/AuthValidator";
import GenericHeader from "../../components/GenericHeader";
import Layout from "../../components/Layout/Layout";
import SettingsComponent from "../../components/Settings/SettingsComponent";

const Settings = () => {
  return (
    <AuthValidator>
      <GenericHeader title="Settings" />
      <Layout>
        <SettingsComponent />
      </Layout>
    </AuthValidator>
  );
};

export default Settings;
