import axios from "axios";
import { API_CODES } from "../utils/constants";
import { queryClient } from "../utils/helper";
import qs from "qs";

const requestObj = axios.create({
  baseURL: "https://web-app-sbs.el.r.appspot.com/api"
});

requestObj.interceptors.request.use(function (config) {
  let token = JSON.parse(localStorage.getItem("auth_token"));

  if (!token) {
    return config;
  }
  token = "Bearer " + token;
  return { ...config, headers: { ...config.headers, Authorization: token } };
});

requestObj.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error.response.status === API_CODES.UNAUTHORIZED) {
    queryClient.clear();
    localStorage.clear();
  }
  throw error;
});

export const postAPIService = async (url, body) => {
  try {
    const res = await requestObj
      .post(url, body);
    return res;
  } catch (e) {
    throw e.response;
  }
};

export const putAPIService = async (url, body, type) => {
  const headers = {
    "Content-Type": type ?? "application/json"
  }
  try {
    const res = await requestObj
      .put(url, body, {
        headers: headers
      });
    return res;
  } catch (e) {
    throw e.response;
  }
};

export const getAPIService = async (url, params) => {
  try {
    const res = await requestObj
      .get(url, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: "repeat" })
        }
      });
    return res;
  } catch (e) {
    throw e.response;
  }
};

export const deleteAPIService = async (url, data) => {
  try {
    const res = await requestObj
      .delete(url, { data });
    return res;
  } catch (e) {
    throw e.response;
  }
};
