/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "react-query";
import {
  editStudent,
  getTurnover,
  getTurnoverYears,
  postPromotionEnded,
  postTurnOverDetails,
  postTurnOverSession,
  putTurnOverDetails,
  registerStudent,
  registerUser,
  removeStudent,
} from "../services/adminServices";
import { queryClient } from "../utils/helper";

export const addUser = () => {
  return useMutation((data) => registerUser(data));
};

export const addStudent = () => {
  return useMutation((data) => registerStudent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("students-list");
    },
  });
};

export const updateStudent = (id) => {
  return useMutation((data) => editStudent(data, id), {
    enabled: !!id || id === 0,
    onSuccess: () => {
      queryClient.invalidateQueries("students-list");
      queryClient.invalidateQueries("student");
      queryClient.invalidateQueries("turn-over");
    },
  });
};

export const deleteStudent = () => {
  return useMutation((id) => removeStudent(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("students-list");
    },
  });
};

export const fetchTurnOverYears = () => {
  return useQuery(["turn-over-year-list"], () => getTurnoverYears());
};

export const fetchTurnover = () => {
  return useQuery(["turn-over"], () => getTurnover());
};

export const addTurnOverDetails = () => {
  return useMutation((data) => postTurnOverDetails(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("turn-over");
    },
  });
};

export const updateTurnOverDetails = () => {
  return useMutation((data) => putTurnOverDetails(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("turn-over");
    },
  });
};

export const createNewSession = () => {
  return useMutation((data) => postTurnOverSession(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("turn-over-year-list");
    },
  });
};

export const endPromotion = () => {
  return useMutation(() => postPromotionEnded(), {
    onSuccess: () => {
      queryClient.invalidateQueries("students-list");
    },
  });
};