import React from "react";
import AuthValidator from "../../components/AuthValidator";
import GenericHeader from "../../components/GenericHeader";
import Layout from "../../components/Layout/Layout";

function AttendenceRecords() {
  return (
    <AuthValidator>
      <GenericHeader title="Attendence Records" />
      <Layout>
        <div>AttendenceRecords</div>
      </Layout>
    </AuthValidator>
  );
}

export default AttendenceRecords;
