import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { Link } from "react-router-dom";
import { getMobileBool } from "../../../custom-hooks/mediaHooks";
import { PAGINATION, ROUTES } from "../../../utils/constants";
import {
  getSelectedClass,
  getStudentCellClassName,
  queryClient,
} from "../../../utils/helper";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
    renderCell: ({ row }) => (
      <Link to={ROUTES.DASHBOARD_STUDENT_DETAILS(row.student_id)}>
        {row?.name}
      </Link>
    ),
    cellClassName: ({ row }) => getStudentCellClassName(row),
  },
  {
    field: "class",
    headerName: "Class",
    width: 100,
    renderCell: (p) => getSelectedClass(p.value),
  },
  { field: "father_name", headerName: "Father's Name", width: 150 },
  { field: "mother_name", headerName: "Mother's Name", width: 150 },
  { field: "gender", headerName: "Gender", width: 150 },
  { field: "section", headerName: "Section", width: 150 },
  { field: "mobile_number", headerName: "Mobile", width: 150 },
  { field: "address", headerName: "Address", width: 150 },
  {
    field: "fees",
    headerName: "Balance Fee",
    width: 150,
    valueGetter: (params) => params.row.fees.balance_fees,
  },
];

export default function StudentListTableComponent({ rows, excludeCols = [] }) {
  const isMobile = getMobileBool();
  const selected_filter = queryClient.getQueryData("dashboard_selected_filter");
  const selected_filter_value = queryClient.getQueryData(
    "dashboard_selected_filter_value"
  );
  const [selectedFilter, setSelectedFilter] = React.useState(
    selected_filter ?? "name"
  );
  const [value, setValue] = React.useState(selected_filter_value ?? "");

  const filteredRow = React.useMemo(() => {
    return rows?.filter((item) => {
      if (
        [
          "name",
          "father_name",
          "mother_name",
          "address",
          "mobile_number",
          "section",
          "status",
        ].includes(selectedFilter)
      ) {
        return item[selectedFilter]
          ?.toLowerCase()
          .includes(value.toLowerCase());
      }
      if ("gender" === selectedFilter) {
        return item[selectedFilter]?.toLowerCase() === value?.toLowerCase();
      }
      return rows;
    });
  }, [selectedFilter, value, rows]);

  return (
    <div style={{ height: "70vh", width: "100%" }}>
      <Box style={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
        <Select
          size="small"
          style={{
            width: isMobile ? "rem" : "10rem",
          }}
          value={selectedFilter}
          onChange={(e) => {
            setSelectedFilter(e.target.value);
            setValue("");
            queryClient.setQueryData(
              "dashboard_selected_filter",
              e.target.value
            );
          }}
          renderValue={(selected) => {
            return (
              columns.find((item) => item.field === selected)?.headerName ??
              "DEV FILTER"
            );
          }}
        >
          {Object.values([
            ...columns,
            { field: "status", headerName: "Status" },
          ])
            .filter((item) => item.field !== "class" && item.field !== "fees")
            .map(({ field, headerName }) => (
              <MenuItem value={field} key={field}>
                <Checkbox size="small" checked={selectedFilter === field} />
                <ListItemText primary={headerName} />
              </MenuItem>
            ))}
        </Select>
        {[
          "name",
          "father_name",
          "mother_name",
          "address",
          "mobile_number",
          "section",
          "status",
        ].includes(selectedFilter) && (
          <TextField
            placeholder="Type something..."
            size="small"
            defaultValue={value}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setValue(e.target.value);
                queryClient.setQueryData(
                  "dashboard_selected_filter_value",
                  e.target.value
                );
              }
            }}
          />
        )}
        {selectedFilter === "gender" && (
          <Select
            size="small"
            style={{
              width: isMobile ? "rem" : "10rem",
            }}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            renderValue={(selected) => {
              return selected;
            }}
          >
            {["MALE", "FEMALE"].map((item) => (
              <MenuItem value={item} key={item}>
                <Checkbox size="small" checked={value === item} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
      <DataGrid
        rows={filteredRow ?? []}
        getRowId={(row) => row.student_id}
        columns={columns.filter((item) => !excludeCols.includes(item?.field))}
        rowsPerPageOptions={[20]}
        initialState={{
          pagination: {
            pageSize: PAGINATION.LIMIT,
          },
        }}
        disableColumnMenu
      />
    </div>
  );
}
