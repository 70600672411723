/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from "react-query";
import {
  fetchCurrentUser,
  getStudent,
  studentList,
} from "../services/clientServices";
import { queryClient } from "../utils/helper";

export const currentUser = () => {
  return useQuery(["currentUser"], () => fetchCurrentUser());
};

export const getCurrentUser = () => {
  return queryClient.getQueryData("currentUser") ?? {};
};

export const getStudentList = (currentClass) => {
  const reqObj = {
    class: currentClass ?? "",
  };
  return useQuery(["students-list", reqObj], () => studentList(reqObj), {
    enabled: !!`${currentClass}`.length,
  });
};

export const getStudentData = (id) => {
  return useQuery(["student", id], () => getStudent(id), {
    enabled: !!id || id === 0,
  });
};
