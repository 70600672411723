import { ExpandMore, MoreHoriz } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  InputLabel,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import {
  addTurnOverDetails,
  createNewSession,
  fetchTurnover,
  fetchTurnOverYears,
  updateTurnOverDetails,
} from "../../custom-hooks/administration";
import { getStudentList } from "../../custom-hooks/client";
import { CLASS_LIST, SESSION_MONTHS } from "../../utils/constants";
import { areDateEqual, isDateGreater, toDate } from "../../utils/helper";
import Loader from "../Loader";

const useStyles = makeStyles(() => ({
  table: {
    "& td, th": {
      padding: 0,
    },
  },
}));

const FeeDetailPageExpenseTrack = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modal, setModal] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [showFullSummary, setShowFullSummary] = useState(null);
  const [showPrevYearData, setShowPrevYearData] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const todayDate = new Date();

  const { isLoading: isLoadingTurnOverYears, data: turnOverYears } =
    fetchTurnOverYears();

  const { isLoading: isLoadingTurnOver, data: turnOver } = fetchTurnover();
  const { isLoading: isLoadingFeeDetails, data: feeDetails } = getStudentList(
    CLASS_LIST.map((item) => item?.value)
  );

  const todayFeeData = (date) => {
    let resFee = 0;
    feeDetails?.forEach((item) => {
      item?.fees?.fee_details?.forEach((fee) => {
        if (areDateEqual(toDate(fee.date), date)) {
          resFee += fee.amount;
        }
      });
    });
    return resFee;
  };

  const todayPrevData = (date) => {
    let resFee = 0;
    feeDetails?.forEach((item) => {
      item?.fees?.prev_fee_details?.forEach((fee) => {
        if (areDateEqual(toDate(fee.date), date)) {
          resFee += fee.amount;
        }
      });
    });
    return resFee;
  };

  const addTurnOverDetailsMutation = addTurnOverDetails();
  const updateTurnoverDetailsMutation = updateTurnOverDetails();
  const createNewSessionMutation = createNewSession();

  const lastSessionYear =
    turnOverYears?.length && turnOverYears[turnOverYears?.length - 1]?.year;

  const currentSession = useMemo(() => {
    if (!lastSessionYear) {
      const month = todayDate.getMonth();
      if (month > 2) {
        return todayDate.getFullYear();
      }
      return todayDate.getFullYear() - 1;
    }
    if (isDateGreater(todayDate, new Date(lastSessionYear + 1, 2, 31))) {
      return lastSessionYear + 1;
    } else {
      return lastSessionYear;
    }
  }, [turnOverYears, todayDate]);

  useEffect(() => {
    if (
      addTurnOverDetailsMutation?.isSuccess ||
      updateTurnoverDetailsMutation?.isSuccess
    ) {
      setModal("");
      setSelectedDate(null);
    }
  }, [
    addTurnOverDetailsMutation?.isSuccess,
    updateTurnoverDetailsMutation?.isSuccess,
  ]);

  const currentMonth = !selectedYear
    ? todayDate?.getMonth() <= 2
      ? todayDate?.getMonth() + 11
      : todayDate?.getMonth() - 1
    : 99999;

  const getRows = (month, year) => {
    return turnOver
      ?.filter((item) => {
        const dataArr = item?.date?.split("-");
        const mon = parseInt(dataArr[1]);
        const checkYear =
          mon <= 3 ? parseInt(dataArr[2]) - 1 : parseInt(dataArr[2]);
        if (mon === month && year === checkYear) {
          return true;
        }
        return false;
      })
      .map((item, index) => ({ ...item, id: index }));
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      renderCell: ({ value }) => (
        <Typography
          style={{ color: "#5C5CFF", cursor: "pointer" }}
          onClick={() => setSelectedDate(toDate(value))}
        >
          {value}
        </Typography>
      ),
      width: 100,
    },
    {
      field: "total_income",
      headerName: "Total Income",
      renderCell: ({ row }) => row?.fee + (row?.other_income ?? 0),
      width: 100,
    },
    {
      field: "expense",
      headerName: "Expense",
      width: 100,
      renderCell: ({ row }) =>
        row?.construction +
        row?.fuel +
        row?.transport +
        row?.labour +
        row?.office +
        row?.other +
        row?.stationary +
        row?.teacher,
    },
    {
      field: "result",
      headerName: "Profit/Loss",
      width: 100,
      renderCell: ({ row }) =>
        row?.fee +
        (row?.other_income ?? 0) -
        (row?.construction +
          row?.fuel +
          row?.transport +
          row?.labour +
          row?.office +
          row?.other +
          row?.stationary +
          row?.teacher),
    },
  ];

  const showExpenseModal =
    !!selectedDate || modal === "today-record" || modal === "prev-record-curr";

  const selectedData = useMemo(() => {
    if (!selectedDate) return null;
    return turnOver.find((item) => {
      const itemDate = toDate(item?.date);
      return areDateEqual(itemDate, selectedDate);
    });
  }, [selectedDate, turnOver]);

  const monthWiseDetails = useMemo(() => {
    let res = {};
    SESSION_MONTHS.forEach((month) => {
      const monthData = getRows(month.month, selectedYear ?? currentSession);
      res[month.month] = {
        total_income: monthData?.reduce(
          (sum, item) => sum + item?.fee + (item?.other_income ?? 0),
          0
        ),
        total_expense: monthData?.reduce(
          (sum, item) =>
            sum +
            item?.construction +
            item?.fuel +
            item?.labour +
            item?.office +
            item?.other +
            item?.stationary +
            item?.teacher +
            item?.transport,
          0
        ),
        total_fees: monthData?.reduce((sum, item) => sum + item?.fee, 0),
        total_other_income: monthData?.reduce(
          (sum, item) => sum + (item?.other_income ?? 0),
          0
        ),
        total_construction: monthData?.reduce(
          (sum, item) => sum + item?.construction,
          0
        ),
        total_fuel: monthData?.reduce((sum, item) => sum + item?.fuel, 0),
        total_labour: monthData?.reduce((sum, item) => sum + item?.labour, 0),
        total_office: monthData?.reduce((sum, item) => sum + item?.office, 0),
        total_other: monthData?.reduce((sum, item) => sum + item?.other, 0),
        total_stationary: monthData?.reduce(
          (sum, item) => sum + item?.stationary,
          0
        ),
        total_teacher: monthData?.reduce((sum, item) => sum + item?.teacher, 0),
        total_transport: monthData?.reduce(
          (sum, item) => sum + item?.transport,
          0
        ),
      };
    });
    return res;
  }, [turnOver, turnOverYears, selectedYear, currentSession]);

  if (isLoadingTurnOverYears || isLoadingFeeDetails) {
    return <Loader />;
  }

  return (
    <>
      <Box style={{ borderBottom: "1px solid #CCC" }}>
        <Button
          variant="contained"
          sx={{ mt: 1, mb: 1 }}
          onClick={() => {
            setShowPrevYearData((p) => !p);
            setSelectedYear(null);
          }}
        >
          {!showPrevYearData ? "All Years" : "Current Session"}
        </Button>
      </Box>
      {!showPrevYearData ? (
        <>
          {(!currentSession || lastSessionYear < currentSession) && (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: 700,
                  borderBottom: "1px solid #CCC",
                }}
              >
                No Session Present, Create One
              </Typography>
              <Button
                variant="contained"
                onClick={() =>
                  createNewSessionMutation.mutate({ year: currentSession })
                }
              >
                Create New Session
              </Button>
            </Box>
          )}
          {!!currentSession && lastSessionYear >= currentSession && (
            <Typography
              style={{
                textAlign: "center",
                fontWeight: 700,
                borderBottom: "1px solid #CCC",
              }}
            >
              Session {selectedYear ?? currentSession} -{" "}
              {(selectedYear ?? currentSession) + 1}
            </Typography>
          )}
          {!!currentSession &&
            lastSessionYear >= currentSession &&
            SESSION_MONTHS.map((item) => (
              <Accordion key={item?.id}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography style={{ fontWeight: 700 }}>
                    {item?.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    ...(currentMonth > item?.id && { height: "430px" }),
                  }}
                >
                  {item?.id === currentMonth - 1 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Button
                        variant="contained"
                        sx={{ mb: 1 }}
                        onClick={() => setModal("today-record")}
                      >
                        Add Today
                      </Button>
                      <Button
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        <MoreHoriz />
                      </Button>
                    </Box>
                  )}
                  {currentMonth > item?.id ? (
                    <>
                      {showFullSummary !== item?.month && (
                        <Box
                          style={{
                            height:
                              item?.month !== currentMonth - 2 ? "85%" : "90%",
                          }}
                        >
                          <DataGrid
                            loading={isLoadingTurnOver}
                            rows={
                              getRows(
                                item?.month,
                                selectedYear ?? currentSession
                              ) ?? []
                            }
                            columns={columns ?? []}
                            rowsPerPageOptions={[10]}
                            initialState={{
                              pagination: {
                                pageSize: 10,
                              },
                            }}
                            disableColumnMenu
                            disableSelectionOnClick
                          />
                        </Box>
                      )}
                      {item?.id !== currentMonth - 1 && (
                        <Table className={classes.table}>
                          <TableHead>
                            <TableCell>Total Income</TableCell>
                            <TableCell>Total Expense</TableCell>
                            <TableCell>Total Profit/Loss</TableCell>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                {monthWiseDetails[item?.month]?.total_income}
                              </TableCell>
                              <TableCell>
                                {monthWiseDetails[item?.month]?.total_expense}
                              </TableCell>
                              <TableCell>
                                {monthWiseDetails[item?.month]?.total_income -
                                  monthWiseDetails[item?.month]?.total_expense}
                              </TableCell>
                            </TableRow>
                            {showFullSummary === item?.month && (
                              <TableRow>
                                <TableCell colSpan={3}>
                                  <Box>
                                    <Typography>
                                      Total Fee :{" "}
                                      {
                                        monthWiseDetails[item?.month]
                                          ?.total_fees
                                      }
                                    </Typography>
                                    <Typography>
                                      Total Other Income:{" "}
                                      {
                                        monthWiseDetails[item?.month]
                                          ?.total_other_income
                                      }
                                    </Typography>
                                    <div style={{ marginBottom: "3rem" }} />
                                    <Typography>
                                      Total Construction Expense:{" "}
                                      {
                                        monthWiseDetails[item?.month]
                                          ?.total_construction
                                      }
                                    </Typography>
                                    <Typography>
                                      Total Fuel Expense:{" "}
                                      {
                                        monthWiseDetails[item?.month]
                                          ?.total_fuel
                                      }
                                    </Typography>
                                    <Typography>
                                      Total Teacher Expense:{" "}
                                      {
                                        monthWiseDetails[item?.month]
                                          ?.total_teacher
                                      }
                                    </Typography>
                                    <Typography>
                                      Total Labour Expense:{" "}
                                      {
                                        monthWiseDetails[item?.month]
                                          ?.total_labour
                                      }
                                    </Typography>
                                    <Typography>
                                      Total Office Expense:{" "}
                                      {
                                        monthWiseDetails[item?.month]
                                          ?.total_office
                                      }
                                    </Typography>
                                    <Typography>
                                      Total Stationary Expense:{" "}
                                      {
                                        monthWiseDetails[item?.month]
                                          ?.total_stationary
                                      }
                                    </Typography>
                                    <Typography>
                                      Total Transport Expense:{" "}
                                      {
                                        monthWiseDetails[item?.month]
                                          ?.total_transport
                                      }
                                    </Typography>
                                    <Typography>
                                      Total Other Expense:{" "}
                                      {
                                        monthWiseDetails[item?.month]
                                          ?.total_other
                                      }
                                    </Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell colSpan={3}>
                                <Typography
                                  style={{
                                    color: "#5C5CFF",
                                    cursor: "pointer",
                                    fontSize: "12px",
                                  }}
                                  onClick={() =>
                                    setShowFullSummary((p) =>
                                      !!p ? null : item?.month
                                    )
                                  }
                                >
                                  {showFullSummary
                                    ? "Show Less"
                                    : "Show More..."}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </>
                  ) : (
                    <Typography>Ahead of time entries not allowed</Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
        </>
      ) : (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "2rem",
          }}
        >
          {turnOverYears
            ?.filter((_, index) => index < turnOverYears?.length - 1)
            ?.map((item) => (
              <Button
                variant="contained"
                sx={{ mt: 1 }}
                onClick={() => {
                  setSelectedYear(item?.year);
                  setShowPrevYearData(false);
                }}
              >
                {item?.year}
              </Button>
            ))}
        </Box>
      )}
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setModal("prev-record-curr")}>
          Add Previous Record
        </MenuItem>
      </Menu>
      <Dialog
        open={showExpenseModal}
        onClose={() => {
          setModal("");
          setSelectedDate(null);
        }}
      >
        {showExpenseModal && (
          <Box sx={{ p: 1 }}>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: 700,
                marginBottom: "1rem",
              }}
            >
              {!selectedDate
                ? `Add ${modal === "today-record" ? "Today's " : "Previous "}
              Record`
                : "Update Record"}
            </Typography>
            <Formik
              initialValues={{
                date: selectedData?.date
                  ? toDate(selectedData?.date)
                  : modal === "today-record"
                  ? todayDate
                  : null,
                fee: selectedData?.date
                  ? todayFeeData(toDate(selectedData?.date))
                  : modal === "today-record"
                  ? todayFeeData(todayDate)
                  : 0,
                other_income: modal !== "today-record"
                  ? (selectedData?.other_income ?? 0)
                  : (todayPrevData(todayDate) ?? 0),
                teacher: selectedData?.teacher ?? 0,
                stationary: selectedData?.stationary ?? 0,
                office: selectedData?.office ?? 0,
                transport: selectedData?.transport ?? 0,
                fuel: selectedData?.fuel ?? 0,
                construction: selectedData?.construction ?? 0,
                labour: selectedData?.labour ?? 0,
                other: selectedData?.other ?? 0,
              }}
              onSubmit={(values) => {
                const data = {
                  ...values,
                  date: format(values?.date, "dd-MM-yyyy"),
                };
                const isPrevRecordAvail = turnOver.find((item) => {
                  const itemDate = toDate(item?.date);
                  return areDateEqual(itemDate, values?.date);
                });

                if (!!isPrevRecordAvail || !!selectedDate) {
                  updateTurnoverDetailsMutation.mutate(data);
                } else {
                  addTurnOverDetailsMutation.mutate(data);
                }
              }}
            >
              {(formProps) => (
                <>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="Date"
                        name={"date"}
                        value={formProps?.values?.date}
                        inputFormat="dd-MM-yyyy"
                        onChange={(newValue) => {
                          formProps.setFieldValue("date", newValue);
                          formProps.setFieldValue(
                            "fee",
                            todayFeeData(newValue)
                          );
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <InputLabel>Fees</InputLabel>
                        <TextField
                          name="fee"
                          size="small"
                          onChange={formProps.handleChange}
                          value={formProps.values.fee}
                          disabled
                        />
                      </Box>
                      <Box>
                        <InputLabel>Other Income</InputLabel>
                        <TextField
                          name="other_income"
                          size="small"
                          onChange={formProps.handleChange}
                          value={formProps.values.other_income}
                        />
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <InputLabel>Teacher</InputLabel>
                        <TextField
                          name="teacher"
                          size="small"
                          onChange={formProps.handleChange}
                          value={formProps.values.teacher}
                        />
                      </Box>
                      <Box>
                        <InputLabel>Stationary</InputLabel>
                        <TextField
                          name="stationary"
                          size="small"
                          onChange={formProps.handleChange}
                          value={formProps.values.stationary}
                        />
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <InputLabel>Office</InputLabel>
                        <TextField
                          name="office"
                          size="small"
                          onChange={formProps.handleChange}
                          value={formProps.values.office}
                        />
                      </Box>
                      <Box>
                        <InputLabel>Transport</InputLabel>
                        <TextField
                          name="transport"
                          size="small"
                          onChange={formProps.handleChange}
                          value={formProps.values.transport}
                        />
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <InputLabel>Fuel</InputLabel>
                        <TextField
                          name="fuel"
                          size="small"
                          onChange={formProps.handleChange}
                          value={formProps.values.fuel}
                        />
                      </Box>
                      <Box>
                        <InputLabel>Construction</InputLabel>
                        <TextField
                          name="construction"
                          size="small"
                          onChange={formProps.handleChange}
                          value={formProps.values.construction}
                        />
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <InputLabel>Labour</InputLabel>
                        <TextField
                          name="labour"
                          size="small"
                          onChange={formProps.handleChange}
                          value={formProps.values.labour}
                        />
                      </Box>
                      <Box>
                        <InputLabel>Other</InputLabel>
                        <TextField
                          name="other"
                          size="small"
                          onChange={formProps.handleChange}
                          value={formProps.values.other}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "10px 0 0 0",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={formProps.handleSubmit}
                    >
                      {addTurnOverDetailsMutation?.isLoading ? (
                        <Loader />
                      ) : (
                        "Save"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        formProps.handleReset();
                        setModal("");
                        setSelectedDate(null);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
              )}
            </Formik>
          </Box>
        )}
      </Dialog>
    </>
  );
  //https://web-app-sbs.el.r.appspot.com
  //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
};

export default FeeDetailPageExpenseTrack;
