import React from "react";
import AuthValidator from "../components/AuthValidator";
import FeeDetails from "../components/FeeDetails/FeeDetails";
import GenericHeader from "../components/GenericHeader";
import Layout from "../components/Layout/Layout";

const Fee = () => {
  return (
    <AuthValidator>
      <GenericHeader title="Fee Details" />
      <Layout>
        <FeeDetails />
      </Layout>
    </AuthValidator>
  );
};

export default Fee;
