import { getAPIService, postAPIService } from "../services/index"

export const fetchCurrentUser = async () => {
  try {
    const res = await getAPIService("/me");
    return res.data;
  } catch (e) {
    throw e;
  }
}

export const loginUser = async (obj) => {
  try {
    const res = await postAPIService("/auth/login", obj);
    return res.data;
  } catch (e) {
    throw e;
  }
}

export const studentList = async (params) => {
  try {
    const res = await getAPIService("/student", params);
    return res.data;
  } catch (e) {
    throw e;
  }
}

export const getStudent = async (id) => {
  try {
    const res = await getAPIService(`/student/${id}`);
    return res.data;
  } catch (e) {
    throw e;
  }
} 