import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import GenericHeader from '../components/GenericHeader'
import LoginCard from '../components/LoginCard'
import { ROUTES } from '../utils/constants';
import { queryClient } from '../utils/helper';

const Login = () => {
  const navigate = useNavigate();
  const currentUser = queryClient.getQueryData("currentUser");
  const isAuthenticated = !!currentUser?.email;

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem("user"));
    const isLogged = isAuthenticated || localUser;
    isLogged && navigate(ROUTES.DASHBOARD);
  }, [isAuthenticated]);

  return (
    <>
      <GenericHeader title="Login"/>
      <LoginCard />
    </> 
  )
}

export default Login