import { CLASS_LIST } from "./constants"

export const getParsedFeeDetails = (data) => {
  let res = {}
  CLASS_LIST.forEach((item) => {
    res[item?.value] = [];
  })
  data?.forEach((item) => {
    res[item?.class] = [...res[item?.class], item]
  })
  return res;
}