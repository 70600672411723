import { useState } from 'react';
import imageCompression from 'browser-image-compression';

export const useCompressAndConvertToBase64 = () => {
  const [loading, setLoading] = useState(false);

  const convert = async (file) => {
    setLoading(true);
    try {
      const options = {
        maxSizeMB: 0.03, // 30KB
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          setLoading(false);
          resolve(reader.result);
        };

        reader.onerror = () => {
          setLoading(false);
          reject(new Error('Error occurred while reading file'));
        };

        reader.readAsDataURL(compressedFile);
      });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return [convert, loading];
};
