import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { Link } from "react-router-dom";
import { PAGINATION, ROUTES } from "../../utils/constants";
import { getSelectedClass, getStudentCellClassName } from "../../utils/helper";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 150,
    renderCell: ({ row }) => (
      <Link to={ROUTES.DASHBOARD_STUDENT_DETAILS(row.student_id)}>
        {row?.name}
      </Link>
    ),
    cellClassName: ({ row }) => getStudentCellClassName(row),
  },
  {
    field: "class",
    headerName: "Class",
    width: 100,
    renderCell: (p) => getSelectedClass(p.value),
  },
  { field: "father_name", headerName: "Father's Name", width: 150 },
  { field: "mother_name", headerName: "Mother's Name", width: 150 },
  { field: "gender", headerName: "Gender", width: 150 },
  { field: "mobile_number", headerName: "Mobile", width: 150 },
  { field: "address", headerName: "Address", width: 150 },
  {
    field: "fees",
    headerName: "Balance Fee",
    width: 150,
    valueGetter: ({ row }) => row.isPrevFeeData ? row.fees.prev_balance_fees : row.fees.balance_fees,
  },
];

const FeeDetailStudentComponent = ({ data, isPrevFeeData }) => {

  const rows = React.useMemo(() => {
    if(data?.length) {
      return data.map(item => ({...item, isPrevFeeData: isPrevFeeData}))
    }
    return []
  }, [data])
  return (
    <div style={{ height: "70vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        getRowId={(row) => row.student_id}
        columns={columns}
        rowsPerPageOptions={[20]}
        initialState={{
          pagination: {
            pageSize: PAGINATION.LIMIT,
          },
        }}
        disableColumnMenu
      />
    </div>
  );
};

export default FeeDetailStudentComponent;
