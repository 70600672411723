import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { COLOR_THEME } from "../../utils/constants";

const FeeDetailsTableComponent = ({
  rows,
  ranged = false,
  setSelectedClass,
  showNil,
  showPrevLeft,
}) => {
  const columns = [
    {
      field: "class_value",
      headerName: "Class",
      flex: 1,
      ...(setSelectedClass && {
        renderCell: ({ row, value }) => (
          <Typography
            style={{...( !showPrevLeft && { color: COLOR_THEME.primary, cursor: "pointer" })}}
            onClick={() => !showPrevLeft ? setSelectedClass(row?.id - 10) : null}
          >
            {/* 10 is the random seed added to the class to make the negetive value to positive */}
            {value}
          </Typography>
        ),
      }),
    },
    ...(!ranged
      ? [
          {
            field: "total_fees",
            headerName: "Total Fees",
            flex: 1,
            renderCell: ({ value }) =>
              value?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
              }),
          },
        ]
      : []),
    ...(!showPrevLeft ? (!showNil
      ? [
          {
            field: "submitted_fees",
            headerName: "Submitted Fees",
            flex: 1,
            renderCell: ({ value }) =>
              value?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
              }),
          },
        ]
      : [
          {
            field: "student_count",
            headerName: "Student Count",
            flex: 1,
          },
        ]) : []),
    ...(!ranged || showPrevLeft
      ? [
          {
            field: "balance_fees",
            headerName: "Balance Fees",
            flex: 1,
            renderCell: ({ value }) =>
              value?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
              }),
          },
        ]
      : []),
  ];

  return (
    <div style={{ height: !ranged ? "63vh" : "60vh", width: "100%" }}>
      <DataGrid
        rows={rows ?? []}
        columns={columns}
        hideFooter
        disableColumnMenu
      />
    </div>
  );
};

export default FeeDetailsTableComponent;
