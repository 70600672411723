import { MenuOpen } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Grid, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../custom-hooks/client";
import { COLOR_THEME, ROUTES } from "../utils/constants";
import { queryClient } from "../utils/helper";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    backgroundColor: COLOR_THEME.header,
    padding: "0.75rem",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const Header = ({ setSidePanelOpen, openSidePanel }) => {
  const classes = useStyles();
  const loggedInUser = getCurrentUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    queryClient.clear();
    navigate(ROUTES.LOGIN);
  };

  return (
    <>
      <Grid className={classes.header}>
        {!openSidePanel ? (
          <MenuIcon
            style={{ fill: COLOR_THEME.light, fontSize: "2rem" }}
            onClick={() => setSidePanelOpen(true)}
          />
        ) : (
          <MenuOpen
            style={{ fill: COLOR_THEME.light, fontSize: "2rem" }}
            onClick={() => setSidePanelOpen(false)}
          />
        )}
        <Avatar onClick={(e) => setAnchorEl(e.currentTarget)}>
          {loggedInUser?.details?.name
            .split(" ")
            .map((item) => item.charAt(0).toUpperCase())
            .join("")}
        </Avatar>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => navigate(ROUTES.PROFILE)}>Profile</MenuItem>
        <MenuItem onClick={() => navigate(ROUTES.SETTINGS)}>Settings</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default Header;
