import { Button } from "@mui/material";
import React from "react";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

const GenericPrintList = ({ list, title }) => {
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <>
      <Button onClick={handlePrint} variant="contained" sx={{ mt: 2 }}>
        Print
      </Button>
      <table
        className="print-list"
        ref={printRef}
        border="1"
        style={{ borderCollapse: "collapse", margin: "auto", display: "none" }}
      >
        {title && (
          <caption style={{ fontSize: "2rem", fontWeight: 700 }}>
            {title}
          </caption>
        )}
        <thead>
          <tr>
            <th style={{ padding: "0.25rem" }}>Sr. No</th>
            <th style={{ padding: "0.25rem" }}>Name</th>
            <th style={{ padding: "0.25rem" }}>Father's Name</th>
            <th style={{ padding: "0.25rem" }}>Address</th>
            <th style={{ padding: "0.25rem" }}>Mobile No.</th>
            <th style={{ padding: "0.25rem" }}>Balance Fee</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, index) => (
            <tr>
              <td style={{ padding: "0.25rem" }} align="center">
                {index + 1}
              </td>
              <td style={{ padding: "0.25rem" }} align="center">
                {item?.name}
              </td>
              <td style={{ padding: "0.25rem" }} align="center">
                {item?.father_name}
              </td>
              <td style={{ padding: "0.25rem" }} align="center">
                {item?.address}
              </td>
              <td style={{ padding: "0.25rem" }} align="center">
                {item?.mobile_number}
              </td>
              <td style={{ padding: "0.25rem" }} align="right">
                {item?.fees?.balance_fees?.toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default GenericPrintList;
